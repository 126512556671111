import { makeAPICall } from "../../action/APIClient/APIClient";

export const authProvider = {
    // send username and password to the auth server and get back credentials
    signUp: async body => makeAPICall("post", "accounts/dj-rest-auth/registration/", body, false, (status, res) => {})
                .then((res) => { return Promise.resolve(res)} )
                .catch((err) => {return Promise.reject(err) })
    ,
    login: async body => makeAPICall("post", "accounts/dj-rest-auth/login/", body, false, (status, res) => {})
            .then(res => {localStorage.setItem("authToken", JSON.stringify(res));Promise.resolve()})
            .catch(res => Promise.reject()),
    // when the dataProvider returns an error, check if this is an authentication error
    checkError: error => Promise.resolve(),
    // when the user navigates, make sure that their credentials are still valid
    checkAuth: params => localStorage.getItem("authToken") ? Promise.resolve() : Promise.reject(),
    // remove local credentials and notify the auth server that the user logged out
    logout: () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem("userDetails");
        return Promise.resolve();
    },
    // get the user's profile
    getIdentity: () => { return JSON.parse(localStorage.getItem("userDetails")) },
    // get the user permissions (optional)
    getPermissions: () => Promise.resolve(),
};