import * as React from 'react';
import { AppBar, useGetList } from 'react-admin';
import { Avatar, Box, FormControl, InputLabel, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { setServiceCenterId } from '../../store/reducer1';
import { SelectDateRange } from '../../UIComponents/dateRangeComponent/appLevelDateRange';
// import Logo from './Logo';


const stringAvatar = (name) => {
  return {
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const useStyles = makeStyles((theme) => ({
  verticleLine: { borderLeft: "1px solid #2a2c2e", height: "2rem", marginLeft: "1rem", marginRight: "1rem" },
}));

export const MyAppBar = (props) => {

  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  let brand = localStorage.getItem("brand")
  let dealership_name = localStorage.getItem("userDetails") && JSON.parse(localStorage.getItem("userDetails")).dealership_name;
  const [serviceCenter, setServiceCenter] = React.useState('');
  const [img, setImg] = React.useState("");

  const isTab = useMediaQuery(() =>
    theme.breakpoints.down("md")
  );

  const { data, isLoading } = useGetList(
    `dealer-brands/${brand}/service-centers/`,
    { filter: { queryParams: "" } }
  );

  const handleChange = (event) => {
    setServiceCenter(event.target.value);
    dispatch(setServiceCenterId(event.target.value))

    data[0].results.forEach((serviceCenter) => {
      if (serviceCenter.id == event.target.value) {
        setImg(serviceCenter.image)
      }
    })
  };


  React.useEffect(() => {
    if (data) {
      dispatch(setServiceCenterId(data[0].results[0].id))
      setServiceCenter(data[0].results[0].id)
      setImg(data[0].results[0].image)
    }
  }, [data])

  return (
    <AppBar

      sx={{
        "& .RaAppBar-toolbar": { height: "4rem" },
        "& .RaAppBar-title": { flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap" },
        whiteSpace: "nowrap",
        overflowX: "scroll",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        "::-webkit-scrollbar": { display: "none" },
      }}

      {...props}
    >

        <Box flex={1} sx={{ display: "flex", alignItems: 'center', flexDirection: "row", textAlign: 'left', marginX: "1rem", whiteSpace: "nowrap" }}>
          {dealership_name && dealership_name != "null" && <Avatar sx={{ bgcolor: "#228ef2" }} {...stringAvatar(dealership_name)} />}
          <h3 style={{ paddingLeft: '1rem', fontSize: '1.5rem' }} >{dealership_name}</h3>
        </Box>

      {!isTab && false && <Box flex={2} sx={{ display: "flex", alignItems: "center", justifyContent: 'right', marginX: "1rem", whiteSpace: "nowrap" }} >

        <Box>
          <img style={{ width: "2.9rem", height: "2.9rem", borderRadius: "100%", marginLeft: "0.4rem", marginRight: "0.5rem" }} src={img} alt="logo" />
          <FormControl variant="outlined" size='small' >
            <InputLabel id="service-center-label" >Service Center</InputLabel>
            <Select
              labelId="service-center-label"
              id="service-center-select"
              value={serviceCenter}
              label="Service Center"
              onChange={handleChange}
              variant="outlined"
              sx={{ color: "white" }}
            >
              {data && data[0].results.map(serviceCenter => {
                return (
                  <MenuItem value={serviceCenter.id}>{serviceCenter.name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <span />
        </Box>

        <Box className={classes.verticleLine}></Box>

        <SelectDateRange />

      </Box>}
    </AppBar>
  )
};