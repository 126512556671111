import React from 'react'
import { useRecordContext } from 'react-admin';
import { formatAMPM } from '../../JS/Utility';

function TimeField({source}) {
    const record = useRecordContext();
    let time = new Date(record[source])
    return record ? (
        <p style={{fontWeight: 500, fontSize: "16px", color: "#7E98ED"}}>{`${formatAMPM(time)}`}</p>
    ) : null;
}

export default TimeField