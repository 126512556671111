import React from "react";
import ContainerWithTitle from "../../UIComponents/Containers/ContainerWithTitle";
import { makeStyles, styled } from "@mui/styles";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, CircularProgress, InputLabel, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useNotify } from "react-admin";
import { makeAPICall } from "../../action/APIClient/APIClient";


const useStyles = makeStyles({
    mainContainer: { padding: "0.5rem", textAlign: "left", width: "100%" },
    childSubContainer: { padding: "0.5rem", paddingBottom: "1.5rem" },
    textField: { width: "100%" },
    textOverflow: { width: '120px', overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: "nowrap" },
    label: { textAlign: 'left' },
    input: { width: "100%", borderRadius: '22px' },
    inputContainerSingle: { paddingTop: "0.4rem", paddingBottom: "0.4rem" },
    inputContainerDouble: { display: 'flex', alignItems: "center", justifyContent: "space-between", padding: "0.5rem", paddingBottom: "1rem" },
    inputChildContainerDouble: { width: "48%" },
})

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const CreateQuotation = (props) => {

    const theme = useTheme();
    const classes = useStyles();
    const notify = useNotify();

    const isMobile = useMediaQuery(() =>
        theme.breakpoints.down("md")
    );

    const [quotation, setQuotation] = React.useState({
        fileName: null,
        image: null,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        vehicle_model: ""
    })

    const [createQuotationInProgress, setCreateQuotationInProgress] = React.useState(false);

    const handleQuotationFile = (file) => {

        if (file.length > 0) {

            var reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = function () {
                console.log(reader.result);
                setQuotation({ ...quotation, fileName: file[0].name, image: reader.result })
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
                notify("Something went wrong")
            };

        } else {
            notify("Something went wrong")
        }

    }

    const handleQuotationCreate = () => {

        if(!quotation.first_name || quotation.first_name == ""){
            notify("Please enter first name")
            return;
        }

        if(!quotation.last_name || quotation.last_name == ""){
            notify("Please enter last name")
            return;
        }

        if(!quotation.phone || quotation.phone == ""){
            notify("Please enter phone number")
            return;
        }

        if(quotation.phone && quotation.phone.split("").length != 10 ){
            notify("Phone number should be of 10 digits")
            return;
        }

        if(!quotation.vehicle_model || quotation.vehicle_model == ""){
            notify("Please enter vehicle model")
            return;
        }

        if(!quotation.image || quotation.image == ""){
            notify("Please provide quotation")
            return;
        }

        const body = {
            first_name: quotation.first_name,
            last_name: quotation.last_name,
            email: quotation.email,
            phone: quotation.phone,
            vehicle_model: quotation.vehicle_model,
            image: quotation.image
        }

        setCreateQuotationInProgress(true);
        makeAPICall("POST", `auto-care/quotations/`, body, true)
            .then((res) => { {props.quotationCreateSuccess(res); setCreateQuotationInProgress(false)} })
            .catch((err) => {notify("Something went wrong"); setCreateQuotationInProgress(false)})
    }

    return (
        <ContainerWithTitle title="Create Quotation" extraStyles={{ padding: "0% 4% 0% 0%" }} height="auto" width="100%" border="none" component={
            <>
                <Box className={classes.mainContainer} >

                    <Box className={classes.childSubContainer} >
                        <h3><b>Enter Details</b></h3>
                        <br></br>
                        {
                            isMobile ?
                                <>
                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >First Name <span style={{ color: "red" }}>*</span></InputLabel>
                                        <TextField className={classes.input} type="text" variant="outlined" value={quotation.first_name} onChange={(e) => setQuotation({ ...quotation, first_name: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>

                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >Last Name <span style={{ color: "red" }}>*</span></InputLabel>
                                        <TextField className={classes.input} type="text" variant="outlined" value={quotation.last_name} onChange={(e) => setQuotation({ ...quotation, last_name: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>

                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >Email Address</InputLabel>
                                        <TextField className={classes.input} type="text" variant="outlined" value={quotation.email} onChange={(e) => setQuotation({ ...quotation, email: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>

                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >Mobile Number <span style={{ color: "red" }}>*</span></InputLabel>
                                        <TextField className={classes.input} type="tel" variant="outlined" value={quotation.phone} onChange={(e) => setQuotation({ ...quotation, phone: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>

                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >Vehicle Model <span style={{ color: "red" }}>*</span></InputLabel>
                                        <TextField className={classes.input} type="text" variant="outlined" value={quotation.vehicle_model} onChange={(e) => setQuotation({ ...quotation, vehicle_model: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>
                                </> : <>
                                    <Box className={classes.inputContainerDouble}>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label} >First Name <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField className={classes.input} variant="outlined" placeholder="Enter First Name" value={quotation.first_name} onChange={(e) => setQuotation({ ...quotation, first_name: e.target.value })} />
                                        </Box>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label} >Last Name <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField className={classes.input} type="text" placeholder="Enter Last Name" value={quotation.last_name} onChange={(e) => setQuotation({ ...quotation, last_name: e.target.value })} variant="outlined" />
                                        </Box>
                                    </Box>
                                    <Box className={classes.inputContainerDouble}>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label}>Email Address</InputLabel>
                                            <TextField className={classes.input} placeholder="Enter Email Address" value={quotation.email} onChange={(e) => setQuotation({ ...quotation, email: e.target.value })} variant="outlined" />
                                        </Box>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label} >Phone Number <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField className={classes.input} placeholder="Enter Phone Number" type="tel" value={quotation.phone} onChange={(e) => setQuotation({ ...quotation, phone: e.target.value })} variant="outlined" />
                                        </Box>
                                    </Box>
                                    <Box className={classes.inputContainerDouble}>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label} >Vehicle Model <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField className={classes.input} placeholder="Enter Model" variant="outlined" value={quotation.vehicle_model} onChange={(e) => setQuotation({ ...quotation, vehicle_model: e.target.value })} />
                                        </Box>
                                    </Box>
                                </>
                        }
                    </Box>

                    <Box className={classes.childSubContainer}>
                        <h3><b>Upload Quotation <span style={{ color: "red" }}>*</span></b></h3>
                        <br></br>
                        <Box sx={{ display: "flex", alignItems: "center", width: '100%', paddingLeft: "0.5rem" }} >
                            {quotation.fileName && <p className={classes.textOverflow} style={{ paddingRight: "0.5rem" }}>{quotation.fileName}</p>}
                            <Button sx={{ minWidth: "180px", height: "45px" }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>Upload file <VisuallyHiddenInput onChange={(e) => handleQuotationFile(e.target.files)} type="file" /></Button>
                        </Box>
                    </Box>

                    <Box sx={{ width: '100%', paddingY: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} className={classes.childSubContainer}>
                        {!createQuotationInProgress ?
                        <>
                        <Button variant="outlined" color="inherit" sx={{ paddingX: "2.5rem", marginX: "0.5rem", height: "2.5rem" }} onClick={() => props.goBack()} >CANCEL</Button>
                        <Button variant="contained" sx={{ paddingX: "2.5rem", marginX: "0.5rem", height: "2.5rem" }} onClick={handleQuotationCreate} >CREATE</Button>
                        </> : 
                        <Button disabled variant="contained" sx={{ width:"90%", maxWidth:"260px", paddingX: "2.5rem", marginX: "0.5rem", height: "2.5rem" }} ><CircularProgress size={20} color="inherit" sx={{marginX:"1rem"}} /> CREATING...</Button>
                        }
                    </Box>
                </Box>
            </>
        } />
    )
}