import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Modal, Typography } from "@mui/material";
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';
import { UpdateServiceBookingWeb } from "../updateBookingStatus.jsx/updateServiceBookingWeb";

const useStyles = makeStyles({
    container: { display: "flex", margin: "1rem" },
    hideScrollBar: { "-ms-overflow-style": "none", "scrollbar-width": "none", "&::-webkit-scrollbar": { display: "none" } },
    inputContainer: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "38vw", minWidth: "500px" },
})

export const BookingStatus = (props) => {

    const classes = useStyles();
    const containerRef = React.useRef(null);
    const [openEditBox, setOpenEditBox] = React.useState(false);

    const updateServiceBooking = (msg) => {
        setOpenEditBox(false);
        props.callback(msg);
    }

    React.useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollLeft = containerRef.current.scrollWidth;
        }
    }, [props.status]);

    return (
        <Box className={classes.container} style={{ justifyContent: "center", width: "80%", margin: 'auto', marginTop: "2rem", marginBottom: 0 }}>
            <Button variant="outlined" color="primary" startIcon={<TipsAndUpdatesTwoToneIcon />} onClick={() => setOpenEditBox(true)} style={{ position: 'absolute', top: 0, right: 0 }}>Update Status</Button>
            <Modal aria-labelledby="update-status-modal" open={openEditBox} onClose={() => setOpenEditBox(false)} closeAfterTransition>
                <Box className={classes.inputContainer}>
                    {props.status.length > 0 && <UpdateServiceBookingWeb currentBookingStatus={props.status[props.status.length - 1].status} callback={updateServiceBooking} />}
                </Box>
            </Modal>
            <Box ref={containerRef} className={classes.hideScrollBar} style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto" }}>
                {props.status.map((state, index) => {
                    return (
                        <Box key={index} className={classes.container} style={{ minWidth: "170px", flexDirection: "column", alignItems: 'center' }} sx={{ paddingX: 0, marginX: 0 }}>
                            <Box className={classes.container} style={{ width: "100%", alignItems: "center", justifyContent: 'center' }}>
                                <hr style={{ width: "100%", border: "none", borderTop: index == 0 ? "none" : "1px dashed black" }} />
                                <img src={state.icon} style={{ width: "250px", marginLeft: "1rem", marginRight: "1rem" }} ></img>
                                <hr style={{ width: "100%", border: "none", borderTop: index == props.status.length - 1 ? "none" : "1px dashed black" }} />
                            </Box>
                            <Typography variant="h8" sx={{ paddingX: "1rem" }} >{state.status_str}</Typography>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}