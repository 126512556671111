import React from 'react'
import ContainerWithTitle from '../../../UIComponents/Containers/ContainerWithTitle'
import DoughnutChart from '../../../UIComponents/Charts/DoughnutChart'
import { Typography } from '@mui/material';

function ServiceStatusChart(props) {

    const chartData = [
        { region: 'Completed', val: props.stats.completed || 1 },
        { region: 'Ongoing', val: props.stats.service_in_progress || 1 },
        { region: 'Delayed', val: props.stats.service_paused || 1 }
    ];

  return (
    <ContainerWithTitle title="Service Status" height="350px" extraStyles={{padding: "0% 0% 0% 0%", width: "40%"}} component={
        <div style={{display: "grid", justifyItems: "start", height: "100%", alignContent: "space-around"}}>
            <Typography variant='h5'>Today</Typography>
            <DoughnutChart chartData={chartData} legendPosition="right" />
            <div style={{position: "absolute", zIndex: "1", marginTop: "10rem", marginLeft: "5.1rem"}}>
              <Typography style={{marginRight: "1rem", fontWeight: 600, fontSize: "54px", color: "#444C66"}} variant='h2'>{props.stats.total_bookings}</Typography>
              <Typography style={{fontWeight: 400, fontSize: "16px", color: "#757A89"}} variant='h5'>Total Services</Typography>
            </div>
        </div>
    } />
  )
}

export default ServiceStatusChart