import React, { useId, useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import { IconButton, Input, InputAdornment, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyle = makeStyles({
    container: {display: "grid", gap: "0.4rem", justifyContent:'center', alignItems:'center', maxWidth:"4rem"},
    label: {marginLeft: "10px", fontFamily: 'Avenir Next', fontWeight: 500, fontSize: "1rem !important",lineHeight: "31px", color: "#001A45", textAlign: "left"},
    input: {padding: "0% 5%", minWidth:"300px", minHeight:"40px", width: "42.5vmin", height: "5vmin", fontSize: "1rem !important", background: "#FFFFFF", border: "1.55642px solid #C4C4C4", borderRadius: "1.3vmin"}
})

function InputWithLabel(props) {
    const classes = useStyle(props);
    const [showPassword, setShowPassword] = useState(false)
    const id = useId()
    return (
        <div className={classes.container}>
            <InputLabel className={classes.label} htmlFor={id}>
                {props.label}
            </InputLabel>
            {props.inputType == "password" ? 
                <Input
                    disableUnderline={true}
                    className={classes.input}
                    id={id} name={props.name}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={props.handleChange}
                    error={props.isError} 
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                // onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                /> :
                <Input 
                    disableUnderline={true}
                    className={classes.input}
                    type={props.inputType}
                    id={id} name={props.name}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={props.handleChange}
                    error={props.isError} 
                    style={{width: props.width}}
                />}
            {props.error && <Typography variant='subtitle2' style={{color: "#f44337", width:"100%", textAlign:'left'}}>{props.error}</Typography>}
        </div>
    )
}

export default InputWithLabel