import React from 'react'
import { useAuthenticated } from 'react-admin';

function Tracking() {
    useAuthenticated();
  return (
    <div>Tracking</div>
  )
}

export default Tracking