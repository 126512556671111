import React from "react";
import { Box } from "@mui/material";
import { useAuthenticated } from "react-admin";
import { CreateGatewayPass } from "./createGatewayPass";
import { ListGatewayPass } from "./listGatewayPass";
import { SuccessGatewayPassSharePage } from "./successGatewayPass";

export const GatewayPass = () => {

    useAuthenticated();
    const [showCreateGatewayPass, setShowCreateGatewayPass] = React.useState(false);
    const [gatewayPassCreate, setGatewayPassCreate] = React.useState({
        gatewayPassCreateSuccess: false,
        share_url: null,
        email: "",
        phone: "",
        vehicle_model: ""
    });

    return (
        <Box sx={{ width: '100%', maxHeight: "100vh", padding: "0.5rem", paddingTop: "2.5rem", fontSize: "0.9rem" }}>
            {
                showCreateGatewayPass ?
                    <CreateGatewayPass goBack={() => setShowCreateGatewayPass(false)} gatewayPassCreateSuccess={(gatewayPass) => { setShowCreateGatewayPass(false); setGatewayPassCreate({ gatewayPassCreateSuccess: true, share_url: gatewayPass.share_url, phone: gatewayPass.phone, email: gatewayPass.email, vehicle_model: gatewayPass.vehicle_model }) }} /> :
                    <ListGatewayPass openCreateGatewayPass={() => setShowCreateGatewayPass(true)} />
            }

            {gatewayPassCreate.gatewayPassCreateSuccess && <SuccessGatewayPassSharePage goBack={() => setGatewayPassCreate({ gatewayPassCreateSuccess: false, share_url: null, phone: "", phone: "", vehicle_model:"" })} gatewayPassUserInfo={gatewayPassCreate} /> }
        </Box>
    )
}