import { Box, Button, CircularProgress, Divider, FormControl, FormLabel, InputLabel, MenuItem, Pagination, Select, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useGetList } from "react-admin";
import { makeAPICall } from "../../../../action/APIClient/APIClient";




const useStyles = makeStyles((theme) => ({
    container: { fontFamily: 'Avenir Next', display: "flex", flexDirection: "column", justifyContent: "center", lineHeight: "27px", letterSpacing: "0.005em", alignItems: "center", borderRadius: "10px", padding: "1rem", backgroundColor: theme.palette.background.default, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)" },
    userUpdateContainer: { display: "flex", padding: "1rem",paddingTop:"2rem", justifyContent: "center", fontSize: "22px", width: "100%" },
    headingH4: { paddingTop: "1rem" },
    divider: { marginBottom: theme.spacing(2), width: "100%", padding: "1rem" },
}))



export const UpdateServiceBookingWeb = (props) => {


    const classes = useStyles()
    const [inputNote, setInuputNote] = React.useState(null)
    const [loading, setIsLoading] = React.useState(false)
    const [currentBookingStatus, setCurrentBookingStatus] = React.useState(props.currentBookingStatus)
    const brand = localStorage.getItem("brand")
    const bookingId = window.location.hash.split("/")[2];

    const { data, isLoading, isError, error } = useGetList(
        `forms/service-bookings/status/`
    );

    const handleChange = (event) => {
        setCurrentBookingStatus(event.target.value);
    };

    const handleSubmit = (event) => {

        setIsLoading(true)
        
        const body = {
            status: currentBookingStatus,
            notes: inputNote
        }

        makeAPICall("POST", `auto-care/service-bookings/${bookingId}/status/`, body, true)
            .then((res) => { setIsLoading(false); props.callback("Successfully Updated Status")})
            .catch((err) => { setIsLoading(false); props.callback(err.detail)})
    }

    React.useEffect(() => {
        setCurrentBookingStatus(props.currentBookingStatus)
    }, [props.currentBookingStatus])


    return (
        <>
            {isLoading ? (
                <div className={classes.container}>
                    <CircularProgress sx={{ color: "#828282", marginY: "6rem" }} />
                </div>
            ) : (
                <div className={classes.container}>
                    <Typography variant="h4" fontWeight={800} className={classes.headingH4}>Update Booking Status</Typography>

                    <Divider className={classes.divider} />

                    <Box className={classes.userUpdateContainer}>
                        <FormControl focused variant="filled" required size="medium" color="primary" sx={{ m: 1, width: "85%", minWidth: "200px" }}>
                            <InputLabel id="service-status-label">Service Status</InputLabel>
                            <Select labelId="service-status-label" id="service-status-label" value={currentBookingStatus} label="Service Status" onChange={handleChange}>
                                {data &&
                                    data[0].status.map((serviceStatus) => {
                                        return <MenuItem value={serviceStatus[0]}>{serviceStatus[1]}</MenuItem>;
                                    })}
                            </Select>
                        </FormControl>
                    </Box>

                    <TextField id="input-note" label="Additional Note (optional)" multiline rows={2} value={inputNote} onChange={(e) => setInuputNote(e.target.value)} variant="filled" sx={{ marginY: "1rem", width: "80%", minWidth: "200px" }} />
                    <br />
                    <Button onClick={handleSubmit} disabled={loading} variant="contained" size="large" sx={{ width: "80%", minWidth: "200px", marginY: "2rem" }} >Submit { loading && <CircularProgress size={"23px"} color="inherit" sx={{marginX:"1rem"}} />}</Button>
                </div>
            )}
        </>
    );
}