import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react'
import { useAuthProvider, useNotify } from 'react-admin';
import InputWithLabel from '../../UIComponents/Inputs/InputWithLabel'
import googleLogo from "./assets/google-logo.png"
import loginGrapics from "./assets/login-graphics.svg"
import GilroyFonts from "./assets/Gilroy-Regular.woff"
import { Link, useNavigate } from 'react-router-dom';
import AlertDialog from '../../UIComponents/AlertDialouge';
import { useTheme } from '@emotion/react';
import useLoginToThePortalHook from './loginToPortalHook';

const useStyles = makeStyles({
    container: { display:"flex", width: "100vw", height: "100%", minHeight: "100vh", justifyContent: "space-between", alignContent: "center" },
    item: { width: "50vw", justifyContent: "space-around", alignContent: "center" },
    item1: { display: "grid", gap: "2rem", justifyItems: "center", marginTop: "3rem !important", paddingBottom: "2rem" },
    item2: { background: "linear-gradient(180deg, #001A45 0%, #1D3688 140.62%)", padding: "2% 7%" },
    button: { height: "48px", width: "20rem", fontFamily: "Avenir Next !important", fontSize: "21px !important", color: "#001A45 !important", textTransform: "none !important", border: "1.5px solid #001A45 !important", borderRadius: "6px !important" },
    login: { background: "#001A45 !important", borderRadius: "6px !important", color: "#fff !important" },
    h4: { fontFamily: 'Gilroy-Bold', fontSize: "36px", lineHeight: "42px", color: "#FFFFFF" },
    h5: { fontFamily: 'Avenir Next', fontStyle: "normal", fontWeight: 400, lineHeight: "34px", color: "#E5E5E5" },
    form: { display: "grid", gap: "2rem", justifyItems: "center", alignItems: "center", textAlign: "left", margin: "auto", },
    subtitle1: { fontFamily: 'Avenir Next !important', fontWeight: "400 !important", fontSize: "16px !important", lineHeight: "20px !important", textAlign: "center" }
})

function SignUp() {
    const [loginCreds, setLoginCreds] = useState({})
    const [emailError, setEmailError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    const [password2Error, setPassword2Error] = useState(null)
    const [error, setError] = useState(null)
    const [agreeTermsAndCondition, setAgreeTermsAndCondition] = useState(false);
    const [disableReservationButton, setDisableReeservationButton] = useState(false);
    const classes = useStyles()
    const authprovider = useAuthProvider();
    const loginToThePortal = useLoginToThePortalHook();

    const notify = useNotify();
    const navigate = useNavigate();
    const theme = useTheme();

    const handleSubmit = e => {

        e.preventDefault();
        setEmailError(null)
        setPasswordError(null)
        setPassword2Error(null)
        setDisableReeservationButton(true);

        authprovider.signUp({ "password1": loginCreds.password1, "password2": loginCreds.password2, "email": loginCreds.email, "username": loginCreds.email, profile_data: { full_name: loginCreds.full_name, dealership_name: loginCreds.dealership_name } })
            .then(res => {
                setDisableReeservationButton(false);

                const loginCred = {
                    "username": loginCreds.email,
                    "password": loginCreds.password1
                }
                sessionStorage.setItem("loginCred", JSON.stringify(loginCred));
                navigate("/login")
                loginToThePortal(loginCred);
            })
            .catch((errors) => {
                // notify(errors)
                let errorFields = Object.keys(errors)
                setDisableReeservationButton(false);
                if (errorFields.includes("email") || errorFields.includes("username")) {
                    setEmailError(errors["email"])
                }
                if (errorFields.includes("password1")) {
                    setPasswordError(errors["password1"])
                }
                if (errorFields.includes("password2")) {
                    setPassword2Error(errors["password2"])
                }
                if (errorFields.includes("non_field_errors")) {
                    setError(errors["non_field_errors"])
                }
            })
    };

    const handleChange = (e) => {
        setLoginCreds({ ...loginCreds, [e.target.name]: e.target.value })
    }

    const GoogleIcon = () => <img style={{ height: "2rem" }} src={googleLogo} alt="google" />

    const isMobile = useMediaQuery(() =>
        theme.breakpoints.down("md")
    );

    return !isMobile ? (
        <Grid container className={classes.container}>
            <Grid item className={`${classes.item} ${classes.item1}`}>
                <Typography variant='h4' style={{ fontFamily: GilroyFonts, fontWeight: 600, fontSize: "48px", lineHeight: "32px", color: "#001A45", margin: "auto", marginBottom: "5vh" }}>Register</Typography>
                {/* <Button variant="outlined" className={classes.button} startIcon={<GoogleIcon/>}>Sign up with Google</Button> */}
                <FormGroup className={classes.form}>
                    <InputWithLabel name="full_name" inputType="text" label="Name" placeholder="Enter your full name" handleChange={handleChange} error={false} value={loginCreds.full_name} />
                    <InputWithLabel name="dealership_name" inputType="text" label="Dealership Name" placeholder="Enter your dealership name" handleChange={handleChange} error={false} value={loginCreds.dealership_name} />
                    <InputWithLabel name="email" inputType="email" label="Email" placeholder="Enter your email" handleChange={handleChange} error={emailError && emailError[0]} value={loginCreds.email} />
                    <InputWithLabel name="phone" inputType="tel" label="Phone" placeholder="Enter your phone number" handleChange={handleChange} error={false} value={loginCreds.phone} />
                    <InputWithLabel name="password1" inputType="password" label="Password" placeholder="Enter your password" handleChange={handleChange} error={passwordError && passwordError[0]} value={loginCreds.password1} />
                    <InputWithLabel name="password2" inputType="password" label="Re-enter your password" placeholder="Re-enter your password" handleChange={handleChange} error={password2Error && password2Error[0]} value={loginCreds.password2} />
                    {error && <Typography variant='subtitle2' style={{ color: "#f44337", marginLeft: "10px" }}>{error}</Typography>}
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <FormControlLabel control={<Checkbox value={agreeTermsAndCondition} onClick={() => { setAgreeTermsAndCondition(!agreeTermsAndCondition) }} />} label="I agree to all terms and privacy policy " />
                    </div>
                    <Button disabled={!agreeTermsAndCondition || disableReservationButton} onClick={handleSubmit} className={`${classes.button} ${classes.login}`} variant='contained' type='submit'>Create Account</Button>
                    <Typography variant='subtitle1' className={classes.subtitle1}>Already have an account? <Link style={{ color: "#2A292E", textDecoration: "none", fontWeight: 600 }} to="/login" >Log In</Link></Typography>
                </FormGroup>
            </Grid>
            <Grid item className={`${classes.item} ${classes.item2}`}>
                <img src={loginGrapics} height={"70%"} alt="login" />
                <Typography variant='h4' className={classes.h4}>Connect with your Customers</Typography>
                <Typography variant='h6' className={classes.h5}>Now connecting and providing services to your customers is easy</Typography>
            </Grid>
        </Grid>
    ) : (
        <Grid container className={classes.container}>
            <Box sx={{ marginTop: "3rem" }} >
                <Typography variant='h4' style={{ fontFamily: GilroyFonts, fontWeight: 600, fontSize: "48px", lineHeight: "32px", color: "#001A45", margin: "auto", marginBottom: "5vh", width: "100vw" }}>Register</Typography>
                {/* <Button variant="outlined" className={classes.button} startIcon={<GoogleIcon/>}>Sign up with Google</Button> */}
                <FormGroup className={classes.form} >
                    <InputWithLabel name="full_name" inputType="text" label="Name" placeholder="Enter your full name" handleChange={handleChange} error={false} value={loginCreds.full_name} />
                    <InputWithLabel name="dealership_name" inputType="text" label="Dealership Name" placeholder="Enter your dealership name" handleChange={handleChange} error={false} value={loginCreds.dealership_name} />
                    <InputWithLabel name="email" inputType="email" label="Email" placeholder="Enter your email" handleChange={handleChange} error={emailError && emailError[0]} value={loginCreds.email} />
                    <InputWithLabel name="phone" inputType="tel" label="Phone" placeholder="Enter your phone number" handleChange={handleChange} error={false} value={loginCreds.phone} />
                    <InputWithLabel name="password1" inputType="password" label="Password" placeholder="Enter your password" handleChange={handleChange} error={passwordError && passwordError[0]} value={loginCreds.password1} />
                    <InputWithLabel name="password2" inputType="password" label="Re-enter your password" placeholder="Re-enter your password" handleChange={handleChange} error={password2Error && password2Error[0]} value={loginCreds.password2} />
                    {error && <Typography variant='subtitle2' style={{ color: "#f44337", marginLeft: "10px" }}>{error}</Typography>}
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <FormControlLabel control={<Checkbox value={agreeTermsAndCondition} onClick={() => setAgreeTermsAndCondition(!agreeTermsAndCondition)} />} label="I agree to all terms and privacy policy " />
                    </div>
                    <Button disabled={!agreeTermsAndCondition || disableReservationButton} onClick={handleSubmit} className={`${classes.button} ${classes.login}`} variant='contained' type='submit'>Create Account</Button>
                    <Typography variant='subtitle1' className={classes.subtitle1}>Already have an account? <Link style={{ color: "#2A292E", textDecoration: "none", fontWeight: 600 }} to="/login" >Log In</Link></Typography>
                </FormGroup>
            </Box>
        </Grid>
    )
}

export default SignUp;