import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useGetList, useList } from 'react-admin';
import { IconButton, TablePagination, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import addIcon from "./assets/addIcon.svg"

const useStyles = makeStyles({
    container: { display: "grid", alignItems: "center", flexDirection: "column", justifyItems: "start", padding: matches => matches ? "2rem 5rem" : "2rem 0rem" },
    listContainer: { display: "flex", flexDirection: "column", alignItems: "center", width: "100%", rowGap: "2rem", marginTop: "2rem", padding: "1rem", background: "#F6F7F9", borderRadius: "12px" },
    title: { fontFamily: 'Avenir Next', fontStyle: "normal", fontWeight: 500, fontSize: "24px", color: "#444C66" },
    add: { width: "100%", height: "64px", display: "flex", background: "#7E98ED", borderRadius: "12px", alignItems: "center" },
    addTexts: { fontFamily: 'Avenir Next', margin: "1rem 0rem", fontWeight: 400, fontSize: "2.5vmin !important", alignItems: "center", color: "#FFFFFF" }
})


export default function PromoList(props) {
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const navigate = useNavigate()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const classes = useStyles(matches)

    const { data, isLoading } = useGetList(
        'dealer-brands/1/promos/',
        { filter: { queryParams: "" } }//?booking-date=2022-07-20 16:22:03 PM
    );

    useEffect(() => {
        console.log("data", data);
    }, [data])

    const handleChangePage = (event, newPage) => {
        console.log("newPage", newPage);
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    return (
        <div className={classes.container}>
            <Typography variant='h4' className={classes.title}>Promos</Typography>
            <div className={classes.add}>
                <IconButton onClick={() => navigate(`/promoCreate`)}>
                    <img style={{ margin: "0.5rem 0.5rem" }} src={addIcon} alt="add" />
                </IconButton>
                <Typography className={classes.addTexts} variant='h5'>
                    Add New Promo (Add promos and customize them according to your brand)
                </Typography>
            </div>
            <div className={classes.listContainer}>
                {data && data[0].results.map((promo, key) => <Card key={key} sx={{ maxWidth: "max-content", position: "relative", display: "flex", background: "transparent", border: "none", boxShadow: "none", width: "100%", gap: "4rem" }}>
                    <CardMedia
                        component="img"
                        alt="promo"
                        height="200"
                        width="600"
                        image={promo.image}
                    />
                    <CardContent style={{ position: "absolute", top: 0, padding: "4rem", fontFamily: 'Gilroy-SemiBold', color: "#FFFFFF" }}>
                        <Typography variant='h3'>
                            {promo.description}
                        </Typography>
                        <Typography variant="h6">
                            {promo.name}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <IconButton onClick={() => navigate(`/promoCreate?id=${promo.id}`)} color="primary" style={{ background: "#fff" }} component="label">
                            {/* <input hidden accept="image/*" type="file" /> */}
                            <EditOutlinedIcon />
                        </IconButton>
                        <IconButton color="primary" style={{ background: "#fff" }} component="label">
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </CardActions>
                </Card>)}
                <TablePagination
                    component="div"
                    count={data && data[0].count}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={data && data[0].results.length}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5]}
                />
            </div>
        </div>
    );
}
