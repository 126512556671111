import { Box, CircularProgress, Pagination, Stack, StepButton, Typography } from '@mui/material';
import { border } from '@mui/system';
import React from 'react'
import { Button, ChipField, Datagrid, EditButton, ListContextProvider, TextField, useGetList, useList } from 'react-admin';
import ContainerWithTitle from '../../../UIComponents/Containers/ContainerWithTitle'
import Table from '../../../UIComponents/Tables/Table'
import { useSelector } from 'react-redux';

const styles = {
  tableRowCell: {fontFamily: 'Avenir Next', fontWeight: "400", fontSize: "18px", textAlign:"center", lineHeight: "27px", letterSpacing: "0.005em", color: "#262A38", whiteSpace: "nowrap"},
  datarid: {overflowY: "scroll", borderRadius: "24px", width: "100%", maxHeight:"400px", "& .RaDatagrid-thead": {height: "56px"}, whiteSpace: "nowrap", color: "#656665", fontWeight: "1000"},
  dataGridHeadingText: {fontWeight: 550},
  statusButton: { border:"1px solid rgba(25, 118, 210, 0.5)", borderRadius:"10px", padding:"0.4rem", backgroundColor:"rgba(25, 118, 210, 0.12)", textAlign:"center", fontFamily: 'Avenir Next', fontWeight: "400", fontSize: "16px", lineHeight: "27px", letterSpacing: "0.005em", whiteSpace: "nowrap"}
}

const TodaysService = (props) => {

  const brand = localStorage.getItem("brand")
  const service_center = useSelector((state) => { return state.store1Reducer.serviceCenterId })
  const [page , setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(5)
  const [count , setCount] = React.useState(1)

  const { data, isLoading, error, isError } = useGetList(
    `dealer-brands/${brand}/service-bookings/?service-center=${service_center}&page=${page}`
  );

  const listContext = useList({ data, isLoading });

  const handlePagination = (event, value) => {
    setPage(value);
  };


  React.useEffect(() => {
    if(data && data[0]){
      setCount(Math.ceil(data[0].count/perPage))
    }

  },[data,perPage])

  
  return !isLoading && !isError && data[0].results.length == 0 ? (
    <ContainerWithTitle title="Today's Service" extraStyles={{ padding: "0% 1% 0% 0%" }} width="100%" component={
      <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection:"column", paddingY:"5rem" }}>
        <Typography variant='h4' fontWeight={700} color="#8a787a" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >No Data Found</Typography>
      </Box>
    } />
  ) : !isLoading && !isError && data[0].results.length > 0 ? (
    <ContainerWithTitle title="Today's Service" extraStyles={{ padding: "0% 1% 0% 0%" }} width="100%" component={
      <ListContextProvider value={{ ...listContext, data: listContext.data && listContext.data[0].results }}>
          <Datagrid bulkActionButtons={false} rowClick={"edit"} resource="/orderProfile" stickyHeader={true} sx={{...styles.datarid, "& .RaDatagrid-headerCell": styles.tableRowCell, "& .MuiButtonBase-root": styles.dataGridHeadingText}} rowStyle={() => ({height: "50px"})}>
            <TextField textAlign="center" style={styles.tableRowCell} source="user.first_name" label="Name" />
            <TextField textAlign="center" style={styles.tableRowCell} source="vehicle.name" label="Car" />
            <TextField textAlign="center" style={styles.tableRowCell} source="service.name" label="Service" />
            <TextField textAlign="center" style={styles.tableRowCell} source="service_center.name" label="Service Center" />
            <TextField textAlign="center" style={styles.tableRowCell} source="status.booking" label="Booking Id" />
            <TextField textAlign="center" style={styles.statusButton} source="status.status_str" label="Status" />
          </Datagrid>

          <Stack spacing={2} sx={{paddingY:"0.8rem"}} >
            <Pagination count={count} variant="outlined" color="primary" size="large" page={page} onChange={handlePagination} />
          </Stack>
      </ListContextProvider>
    } />
  ) : !isError ? (
    <ContainerWithTitle title="Today's Service" extraStyles={{ padding: "0% 1% 0% 0%" }} width="100%" component={
      <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection:"column", paddingY:"5rem" }}>
        <CircularProgress sx={{color:"#828282"}} />
        <Typography variant='h5' fontWeight={800} color="#828282" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Loading...</Typography>
      </Box>
    } />
  ) : (isError || error) && (
    <ContainerWithTitle title="Today's Service" extraStyles={{ padding: "0% 1% 0% 0%" }} width="100%" component={
      <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection:"column", paddingY:"5rem" }}>
        <Typography variant='h4' fontWeight={800} color="#8a787a" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Something Went Wrong :(</Typography>
        {error.detail && <Typography variant='h5' fontWeight={800} color="#828282" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Error Details : {error.detail}</Typography>}
      </Box>
    } />
  )
}

export default TodaysService