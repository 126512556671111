import { createSlice } from "@reduxjs/toolkit"

const store1ReducerSlice = createSlice({
    name: "store1Reducer",
    initialState: {
        isGuestLoggedIn: false,
        shareScreenConfig: {
            renderShareScreen: false,
            shareScreenRequiredInfo: {
                message: "",
                phoneNumber: "",
                email: "",
                mailSub: "",
            }
        },
        serviceCenterId: null,
        maxBookingDataId: null,
        minBookingDataId: null,
        sideBarMenuItems: null,
        filterDataFromDate: undefined,
        filterDataToDate: undefined,
    },
    reducers: {

        setIsGuestLoggedIn (state, action) {
            return {
                ...state, isGuestLoggedIn: action.payload
            }
        },

        setServiceCenterId (state , action) {
            return {
                ...state, serviceCenterId: action.payload
            }
        },

        updateMaxBookingDataId (state,action) {
            return {
                ...state, maxBookingDataId: action.payload
            }
        },

        updateMinBookingDataId (state,action) {
            return {
                ...state, minBookingDataId: action.payload
            }
        },

        setFilterDataFromDate (state, action) {
            return {
                ...state, filterDataFromDate: action.payload
            }
        },

        setFilterDataToDate (state, action) {
            return {
                ...state, filterDataToDate: action.payload
            }
        },

        setSideBarMenuitems (state, action) {
            return {
                ...state, sideBarMenuItems: action.payload
            }
        },

        setShareScreenConfig (state, action) {
            return {
                ...state , shareScreenConfig: action.payload
            }
        }
    }
})

export default store1ReducerSlice.reducer

export const { setServiceCenterId, updateMaxBookingDataId, updateMinBookingDataId, setFilterDataFromDate, setFilterDataToDate, setSideBarMenuitems, setIsGuestLoggedIn,
    setShareScreenConfig } = store1ReducerSlice.actions;