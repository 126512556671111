import React, { useState } from 'react'
import List from "./List"
import Create from "./Create"

function Promos() {
  const [showCreate, setShowCreate] = useState(false)
  return (
    <div>
      {!showCreate ?
        <List setShowCreate={() => setShowCreate(true)} /> :
        <Create handleBack={() => setShowCreate(false)} />}
    </div>
  )
}

export default Promos