import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    tHead: {fontFamily: 'Avenir Next !important', fontWeight: "500 !important", fontSize: "17px !important", lineHeight: "25px !important", letterSpacing: "0.005em", color: "#757A89 !important"},
    tBody: {fontFamily: 'Avenir Next !important', fontWeight: "400 !important", fontSize: "18px !important", lineHeight: "27px !important", letterSpacing: "0.005em", color: "#262A38 !important"}
})

export default function TableWithStickyHeader(props) {
    const classes = useStyles()
    return (
        <>
            <TableContainer sx={{ maxHeight: 380 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {props.columns.map((column, index) => (
                                <TableCell className={classes.tHead} key={index}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.map((row, index) => {
                            return (
                                <TableRow hover key={index}>
                                    {Object.values(row).map((value, i) => 
                                        <TableCell  className={classes.tBody} key={i}>
                                            {value}
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
