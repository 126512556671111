import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import ContainerWithTitle from "../../UIComponents/Containers/ContainerWithTitle";
import { Datagrid, ListContextProvider, TextField, useGetList, useList, useNotify } from "react-admin";
import CallIcon from '@mui/icons-material/Call';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import moment from "moment";


const styles = {
    tableRowCell: { fontFamily: 'Avenir Next', fontWeight: "400", fontSize: "18px", textAlign: "center", lineHeight: "27px", letterSpacing: "0.005em", color: "#262A38", whiteSpace: "nowrap" },
    datarid: { overflowY: "scroll", borderRadius: "24px", width: "100%", maxHeight: "400px", "& .RaDatagrid-thead": { height: "56px" }, whiteSpace: "nowrap", color: "#656665", fontWeight: "1000", backgronuColor: "black" },
    dataGridHeadingText: { fontWeight: 550 },
}

const useStyles = makeStyles({
    mobileMainContainer: { width: "10rem" },
    createGatewayPassContainer: { position: "fixed", width: "100%", bottom: "0", paddingBottom: "1rem", left: "50%", transform: "translateX(-50%)", background: "white", height: "5rem", display: "flex", justifyContent: 'center', alignItems: 'center' },
    createGatewayPassButtonDesktop: { textAlign: "left", width: "98%", paddingTop: "2rem", paddingBottom: "2rem" },
    flexRowContainer: { display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "0.8rem" },
    textOverflow: { maxWidth: '140px', overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: "nowrap" },
    visuallyHiddenDiv: { position: "absolute", zIndex: '1000', opacity: 0 }
})


export const ListGatewayPass = (props) => {

    const theme = useTheme();
    const classes = useStyles();
    const notify = useNotify();

    const isMobile = useMediaQuery(() =>
        theme.breakpoints.down("md")
    );

    const { data, isLoading, error, isError } = useGetList(
        `gatepass/`
    );

    const listContext = useList({ data });

    const getFormatedDate = (date) => {

        let localDate = new Date(date)
        let formatedDate = moment(localDate).format("DD MMM, YYYY");

        return formatedDate.toString();
    }

    const handleCallClick = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleShareWhatsApp = (phoneNumber, message) => {
        window.open(`https://wa.me/+91${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
    }

    const handleCopyShareUrl = (url) => {
        const element = document.createElement("textarea");
        element.value = url;
        document.body.appendChild(element);
        element.select();
        document.execCommand("copy");
        document.body.removeChild(element);
        notify("📋 Copied to clipboard")
    };

    return !isLoading && !isError && data[0].results ? (
        <ContainerWithTitle title="Gateway Pass" extraStyles={{ padding: "0% 4% 0% 0%" }} border="none" height="auto" width="100%" component={
            <>
                {
                    isMobile ?
                        <>
                            <Box sx={{ padding: "0.5rem", paddingTop: "1rem",  width: "100%", maxWidth:"90vw", overflowY: "scroll", height: "70vh" }} >
                                {
                                    data[0].results.length == 0 ?

                                        <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection: "column", paddingY: "5rem", width: "100%", border: "1px solid #D0D5DE", borderRadius: "12px", minHeight: "35rem" }}>
                                            <Typography variant='h4' fontWeight={700} color="#8a787a" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Create your first Gateway Pass...</Typography>
                                        </Box> :

                                        data[0].results.map((gatewayPassInfo) => {
                                            return (
                                                <Box sx={{ paddingY: "1rem" }} >
                                                    <Box sx={{ textAlign: "left" }} >{getFormatedDate(gatewayPassInfo.created_at)}</Box>
                                                    <Box className={classes.flexRowContainer}>
                                                        <Box sx={{ textAlign: "left", paddingX: "15px", }}>
                                                            <Box className={classes.textOverflow} >Name: {gatewayPassInfo.first_name + " " + gatewayPassInfo.last_name}</Box>
                                                            <Box className={classes.textOverflow} >Car Model: {gatewayPassInfo.vehicle_model}</Box>
                                                            <Box className={classes.textOverflow} >Service Type: {gatewayPassInfo.service_type}</Box>
                                                        </Box>
                                                        <Box sx={{display:"flex", width:"auto", alignItems:'center'}}>
                                                        <Box sx={{ paddingX: "4px" }} onClick={() => handleCallClick(gatewayPassInfo.phone)} ><CallIcon fontSize="small" /></Box>
                                                        <Box sx={{ paddingX: "4px" }} onClick={() => { handleShareWhatsApp(gatewayPassInfo.phone, "Gateway Pass: " + gatewayPassInfo.share_url)}} > <WhatsAppIcon fontSize="small" /></Box>
                                                        <Box sx={{ paddingX: "4px" }} onClick={() => { handleCopyShareUrl(gatewayPassInfo.share_url) }} ><ContentCopyIcon fontSize="small" /></Box>
                                                        {/* <Box sx={{ paddingX: "2px" }}> <ArrowForwardIosIcon fontSize="small" /></Box> */}
                                                        </Box>
                                                    </Box>
                                                    <Box className={classes.flexRowContainer}>
                                                        <Box sx={{ paddingX: "10px", color: gatewayPassInfo.delivered ? "green" : "red" }}>{gatewayPassInfo.delivered ? "Delivered" : "Not Delivered"}</Box>
                                                        <Box sx={{ paddingX: "10px" }}>{gatewayPassInfo.vehicle_number}</Box>
                                                    </Box>
                                                    <hr style={{ marginTop: "1rem", width:"100%" }} />
                                                </Box>
                                            )
                                        })
                                }

                                <Box className={classes.createGatewayPassContainer} >
                                    <Button size="large" variant="contained" sx={{ width: "80%", height: "3rem" }} onClick={() => props.openCreateGatewayPass()} >CREATE Gateway Pass</Button>
                                </Box>
                            </Box>
                        </> : <>
                            <Box className={classes.createGatewayPassButtonDesktop} >
                                <Box style={{ display: "flex", alignItems: 'center', width: "fit-content", padding: "1rem", backgroundColor: "whitesmoke", borderRadius: "5px", color: "#194080", cursor: "pointer" }} onClick={() => props.openCreateGatewayPass()} ><h3 style={{ paddingRight: "2rem", fontSize: "1.2rem" }} >Create New Gateway Pass </h3><AddCircleIcon fontSize="large" /></Box>
                            </Box>
                            <ContainerWithTitle title="Recently created Gateway Pass:" extraStyles={{ padding: "0% 4% 0% 0%", width: "98%" }} height="100%" width="100%" component={
                                <>
                                    {
                                        data[0].results.length == 0 ?

                                            <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection: "column", paddingY: "5rem", minHeight: "23rem" }}>
                                                <Typography variant='h4' fontWeight={700} color="#8a787a" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Create your first Gateway Pass...</Typography>
                                            </Box> :

                                            <ListContextProvider value={{ ...listContext, data: listContext.data && listContext.data[0].results }}>
                                                <Datagrid bulkActionButtons={false} rowClick={false} stickyHeader={true} sx={{ ...styles.datarid, "& .RaDatagrid-headerCell": styles.tableRowCell, "& .MuiButtonBase-root": styles.dataGridHeadingText }} rowStyle={() => ({ height: "50px" })}>
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="first_name" label="First Name" />
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="last_name" label="Last Name" />
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="vehicle_model" label="Vehicle Model" />
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="vehicle_number" label="Vehicle Number" />
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="service_type" label="Service Type" />
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="delivered" label="Delivery Status" />
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} source="share_url" label="GatewayPass Link" ><TextField className={classes.visuallyHiddenDiv} textAlign="center" onClick={(e) => { navigator.clipboard.writeText(e.target.textContent).then(() => notify("Copied to clipboard")) }} style={styles.tableRowCell} source="share_url" />Copy Link <ContentCopyIcon sx={{ paddingLeft: "0.4rem" }} /></Box>
                                                </Datagrid>
                                            </ListContextProvider>}
                                </>
                            } />
                        </>
                }
            </>
        } />
    ) : (isError || error) ? (
        <ContainerWithTitle title="GatewayPass" extraStyles={{ padding: "0% 1% 0% 0%" }} height="80vh" width="100%" component={
            <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection: "column", paddingY: "5rem" }}>
                <Typography variant='h4' fontWeight={800} color="#8a787a" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Something Went Wrong :(</Typography>
                {error.detail && <Typography variant='h5' fontWeight={800} color="#828282" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Error Details : {error.detail}</Typography>}
            </Box>
        } />
    ) : !isError && (
        <ContainerWithTitle title="GatewayPass" extraStyles={{ padding: "0% 1% 0% 0%" }} height="80vh" width="100%" component={
            <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection: "column", paddingY: "5rem" }}>
                <CircularProgress sx={{ color: "#828282" }} />
                <Typography variant='h5' fontWeight={800} color="#828282" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Loading...</Typography>
            </Box>
        } />
    )
}