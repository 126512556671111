import React from "react";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const useStyles = makeStyles({
    container: { padding: "1rem", width:"80vw", minWidth:"700px"},
    header: { display: "grid", justifyItems: "start", margin: "1rem" },
    title: { fontWeight: "500", fontSize: "24px", color: "#444C6" },
    info: { display: "flex", width: "100%", height: "60px", background: "#F0F3FB", borderRadius: "12px", alignItems: "center", gap: "1rem", padding: "0rem 1rem", fontWeight: 400, fontSize: "16px", color: "#003399" },
})

export const HeaderInformationContainer = (props) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <header className={classes.header}>
                <h1 className={classes.title}>{props.heading}</h1>
                <div className={classes.info}>
                    {props.icon ? props.icon : <InfoOutlinedIcon />}
                    <p>{props.msg}</p>
                </div>
            </header>
        </div>
    )
}