import React, { useMemo } from "react";
import { Box, InputAdornment, Popover, TextField } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useDispatch, useSelector } from "react-redux";
import { setFilterDataFromDate, setFilterDataToDate } from "../../store/reducer1";
import { dateFormatHandler } from "../../JS/Utility";

const datePickerCss = `
    .rdp-day_selected {
        color: #ffffff;
        background-color: #2196f3 ;
    }
    .rdp-day:hover {
        color: #ffffff;
        background-color: #8cb6ed !important;
    }
    .rdp-day_selected.rdp-day:hover {
        color: #ffffff;
        background-color: #0783e8 !important;
    }
`

export const SelectDateRange = () => {
    const dispatch = useDispatch();

    const filterDateFrom = useSelector(state => state.store1Reducer.filterDataFromDate);
    const filterDateTo = useSelector(state => state.store1Reducer.filterDataToDate);

    const selectedDateRange = useMemo(() => {
        if (filterDateFrom || filterDateTo) {
            return {
                from: filterDateFrom ? new Date(filterDateFrom) : undefined,
                to: filterDateTo ? new Date(filterDateTo) : undefined,
            };
        } else {
            return undefined;
        }
    }, [filterDateFrom, filterDateTo]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showSelectDate, setShowSelectDate] = React.useState(false);


    const handleDateFormat = (selectedDate) => {
        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
        const day = String(selectedDate.getDate()).padStart(2, '0');

        const localDateString = `${year}-${month}-${day}`;

        return localDateString
    }

    const setDateRange = (obj) => {

        console.log("Selected Date: " + JSON.stringify(obj))

        if(obj){
            obj.from ? dispatch(setFilterDataFromDate(handleDateFormat(obj.from))) : dispatch(setFilterDataFromDate(undefined)) ;
            obj.to ? dispatch(setFilterDataToDate(handleDateFormat(obj.to))) : dispatch(setFilterDataToDate(undefined)) ;
        } else {
            dispatch(setFilterDataFromDate(undefined));
            dispatch(setFilterDataToDate(undefined));
        }
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        setShowSelectDate(!showSelectDate);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setShowSelectDate(!showSelectDate);
    };

    return (
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "1rem", input: { color: "white" } }}>
            <TextField
                type="text"
                onClick={handleClick}
                label="From - To"
                variant="outlined"
                value={(filterDateFrom || filterDateTo) ? `${filterDateFrom ? dateFormatHandler(filterDateFrom, "dd/MM/yyyy") : "__/__/____"} - ${filterDateTo ? dateFormatHandler(filterDateTo, "dd/MM/yyyy") : "__/__/____"}` : ""}
                placeholder="Filter Date Range"
                disabled
                sx={{ width: "15rem" }}
                inputProps={{
                    style: {
                        color: "white",
                        cursor: "pointer",
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <DateRangeIcon />
                        </InputAdornment>
                    ),
                }}
            />

            <Popover
                open={showSelectDate}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{ marginTop: '1.2rem' }}
            >
                <style>{datePickerCss}</style>
                <DayPicker
                    mode="range"
                    selected={selectedDateRange}
                    onSelect={setDateRange}
                />
            </Popover>
        </Box>
    );
};
