import React from "react";
import ContainerWithTitle from "../../UIComponents/Containers/ContainerWithTitle";
import { makeStyles, styled } from "@mui/styles";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, CircularProgress, InputLabel, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useNotify } from "react-admin";
import { makeAPICall } from "../../action/APIClient/APIClient";


const useStyles = makeStyles({
    mainContainer: { padding: "0.5rem", textAlign: "left", width: "100%" },
    childSubContainer: { padding: "0.5rem", paddingBottom: "1.5rem" },
    textField: { width: "100%" },
    textOverflow: { width: '120px', overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: "nowrap" },
    label: { textAlign: 'left' },
    input: { width: "100%", borderRadius: '22px' },
    inputContainerSingle: { paddingTop: "0.4rem", paddingBottom: "0.4rem" },
    inputContainerDouble: { display: 'flex', alignItems: "center", justifyContent: "space-between", padding: "0.5rem", paddingBottom: "1rem" },
    inputChildContainerDouble: { width: "48%" },
})

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const CreateGatewayPass = (props) => {

    const theme = useTheme();
    const classes = useStyles();
    const notify = useNotify();

    const isMobile = useMediaQuery(() =>
        theme.breakpoints.down("md")
    );

    const [gatewayPass, setGatewayPass] = React.useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        vehicle_model: "",
        vehicle_number: "",
        service_type: ""
    })

    const [createGatewayPassInProgress, setCreateGatewayPassInProgress] = React.useState(false);

    const handleGatewayPassCreate = () => {

        if(!gatewayPass.first_name || gatewayPass.first_name == ""){
            notify("Please enter first name")
            return;
        }

        if(!gatewayPass.last_name || gatewayPass.last_name == ""){
            notify("Please enter last name")
            return;
        }

        if(!gatewayPass.phone || gatewayPass.phone == ""){
            notify("Please enter phone number")
            return;
        }

        if(gatewayPass.phone && gatewayPass.phone.split("").length != 10 ){
            notify("Phone number should be of 10 digits")
            return;
        }

        if(!gatewayPass.vehicle_model || gatewayPass.vehicle_model == ""){
            notify("Please enter vehicle model")
            return;
        }

        if(!gatewayPass.vehicle_number || gatewayPass.vehicle_number == ""){
            notify("Please enter vehicle number")
            return;
        }

        if(!gatewayPass.service_type || gatewayPass.service_type == ""){
            notify("Please provide service type")
            return;
        }


        const body = {
            first_name: gatewayPass.first_name,
            last_name: gatewayPass.last_name,
            email: gatewayPass.email,
            phone: gatewayPass.phone,
            vehicle_model: gatewayPass.vehicle_model,
            vehicle_number: gatewayPass.vehicle_number,
            service_type: gatewayPass.service_type
        }

        setCreateGatewayPassInProgress(true);
        makeAPICall("POST", `auto-care/gatepass/`, body, true)
            .then((res) => { {props.gatewayPassCreateSuccess(res); setCreateGatewayPassInProgress(false)} })
            .catch((err) => {notify("Something went wrong"); setCreateGatewayPassInProgress(false)})
    }

    return (
        <ContainerWithTitle title="Create Gateway Pass" extraStyles={{ padding: "0% 4% 0% 0%" }} height="auto" width="100%" border="none" component={
            <>
                <Box className={classes.mainContainer} >

                    <Box className={classes.childSubContainer} >
                        <h3><b>Enter Details</b></h3>
                        <br></br>
                        {
                            isMobile ?
                                <>
                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >First Name <span style={{ color: "red" }}>*</span></InputLabel>
                                        <TextField className={classes.input} type="text" variant="outlined" value={gatewayPass.first_name} onChange={(e) => setGatewayPass({ ...gatewayPass, first_name: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>

                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >Last Name <span style={{ color: "red" }}>*</span></InputLabel>
                                        <TextField className={classes.input} type="text" variant="outlined" value={gatewayPass.last_name} onChange={(e) => setGatewayPass({ ...gatewayPass, last_name: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>

                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >Email Address</InputLabel>
                                        <TextField className={classes.input} type="text" variant="outlined" value={gatewayPass.email} onChange={(e) => setGatewayPass({ ...gatewayPass, email: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>

                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >Mobile Number <span style={{ color: "red" }}>*</span></InputLabel>
                                        <TextField className={classes.input} type="tel" variant="outlined" value={gatewayPass.phone} onChange={(e) => setGatewayPass({ ...gatewayPass, phone: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>

                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >Vehicle Model <span style={{ color: "red" }}>*</span></InputLabel>
                                        <TextField className={classes.input} type="text" variant="outlined" value={gatewayPass.vehicle_model} onChange={(e) => setGatewayPass({ ...gatewayPass, vehicle_model: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>

                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >Vehicle Number <span style={{ color: "red" }}>*</span></InputLabel>
                                        <TextField className={classes.input} type="text" variant="outlined" value={gatewayPass.vehicle_number} onChange={(e) => setGatewayPass({ ...gatewayPass, vehicle_number: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>

                                    <Box className={classes.inputContainerSingle} >
                                        <InputLabel className={classes.label} >Service Type <span style={{ color: "red" }}>*</span></InputLabel>
                                        <TextField className={classes.input} type="text" variant="outlined" value={gatewayPass.service_type} onChange={(e) => setGatewayPass({ ...gatewayPass, service_type: e.target.value })} name="given-name" sx={{ paddingBottom: "0.9rem" }} />
                                    </Box>
                                </> : <>
                                    <Box className={classes.inputContainerDouble}>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label} >First Name <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField className={classes.input} variant="outlined" placeholder="Enter First Name" value={gatewayPass.first_name} onChange={(e) => setGatewayPass({ ...gatewayPass, first_name: e.target.value })} />
                                        </Box>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label} >Last Name <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField className={classes.input} type="text" placeholder="Enter Last Name" value={gatewayPass.last_name} onChange={(e) => setGatewayPass({ ...gatewayPass, last_name: e.target.value })} variant="outlined" />
                                        </Box>
                                    </Box>
                                    <Box className={classes.inputContainerDouble}>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label}>Email Address</InputLabel>
                                            <TextField className={classes.input} placeholder="Enter Email Address" value={gatewayPass.email} onChange={(e) => setGatewayPass({ ...gatewayPass, email: e.target.value })} variant="outlined" />
                                        </Box>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label} >Phone Number <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField className={classes.input} placeholder="Enter Phone Number" type="tel" value={gatewayPass.phone} onChange={(e) => setGatewayPass({ ...gatewayPass, phone: e.target.value })} variant="outlined" />
                                        </Box>
                                    </Box>
                                    <Box className={classes.inputContainerDouble}>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label} >Vehicle Model <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField className={classes.input} placeholder="Enter Vehicle Model" value={gatewayPass.vehicle_model} onChange={(e) => setGatewayPass({ ...gatewayPass, vehicle_model: e.target.value })} variant="outlined" />
                                        </Box>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label} >Vehicle Number <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField className={classes.input} placeholder="Enter Vehicle Number" value={gatewayPass.vehicle_number} onChange={(e) => setGatewayPass({ ...gatewayPass, vehicle_number: e.target.value })} variant="outlined" />
                                        </Box>
                                    </Box>
                                    <Box className={classes.inputContainerDouble}>
                                        <Box className={classes.inputChildContainerDouble} >
                                            <InputLabel className={classes.label} >Service Type <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField className={classes.input} placeholder="Provide Service Type" variant="outlined" value={gatewayPass.service_type} onChange={(e) => setGatewayPass({ ...gatewayPass, service_type: e.target.value })} />
                                        </Box>
                                    </Box>
                                </>
                        }
                    </Box>

                    <Box sx={{ width: '100%', paddingY: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} className={classes.childSubContainer}>
                        {!createGatewayPassInProgress ?
                        <>
                        <Button variant="outlined" color="inherit" sx={{ paddingX: "2.5rem", marginX: "0.5rem", height: "2.5rem" }} onClick={() => props.goBack()} >CANCEL</Button>
                        <Button variant="contained" sx={{ paddingX: "2.5rem", marginX: "0.5rem", height: "2.5rem" }} onClick={handleGatewayPassCreate} >CREATE</Button>
                        </> : 
                        <Button disabled variant="contained" sx={{ width:"90%", maxWidth:"260px", paddingX: "2.5rem", marginX: "0.5rem", height: "2.5rem" }} ><CircularProgress size={20} color="inherit" sx={{marginX:"1rem"}} /> CREATING...</Button>
                        }
                    </Box>
                </Box>
            </>
        } />
    )
}