import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, InputLabel, TextField } from "@mui/material";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';


const useStyles = makeStyles({
    label: { textAlign: 'left' },
    colorInput: { width: "100%" },
    colorInputBox: { width: "50px", marginLeft:"1rem", marginRight:'1rem' },
    childContainer: { display: "flex", justifyContent: "space-between", alignItems: "center", maxWidth:"100%", overflowY:"scroll", width:"auto" },
    colorBoxContainer: { display: "flex", justifyContent: "space-between", alignItems: "center", marginRight:"1rem" },
    buttonContainer: {width:"100%", textAlign:"left"}
})

export const ColorPickerInput = (props) => {

    const classes = useStyles();

    const handleColors = (colorHash) => {

        let colorCodeHash;

        if(colorHash){
            colorCodeHash = colorHash.split(",").map(colorCode => colorCode.trim());
        } else {
            colorCodeHash = [];
        }

        props.next(colorCodeHash);
    }

    return (
        <Box>
            <InputLabel className={classes.label} >Color Code:</InputLabel>

            <Box className={classes.childContainer}>
                <Box className={classes.colorBoxContainer}>
                    {
                        props.colorCodes.map((color) => {
                            return (
                                <Box className={classes.colorInputBox}>
                                    <span className="x-button" ></span>
                                    <TextField className={classes.colorInput} type="color" variant="outlined" value={color} placeholder='Select Color Code' />
                                </Box>
                            )
                        })
                    }
                </Box>

                <Box className={classes.buttonContainer}>
                    <TextField type="text" style={{minWidth:"300px", width:"48%"}} value={props.colorCodes.join(",")} onChange={(e) => handleColors(e.target.value)} variant="outlined" placeholder="Enter color separated with commas" />
                </Box>
            </Box>
        </Box>
    )
}