import React from "react";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { OrderProfile } from "./orderProfile";


const useStyles = makeStyles({
    container: { display: "flex", justifyContent: "center", alignItems: "center" },
})


export const OrderProfileContainer = () => {

    const theme = useTheme();
    const classes = useStyles();

    const isMobile = useMediaQuery(() =>
        theme.breakpoints.down("md")
    );

    return !isMobile ? (
        <div className={classes.container} >
            <OrderProfile />
        </div>
    )
        : (
            // edit below for mobile view flow.
            <div className={classes.container} >
                <OrderProfile />
            </div>
        )
}