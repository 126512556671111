import * as React from 'react';
import {
  Chart,
  PieSeries,
  Legend
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import { withStyles } from '@material-ui/core';

const legendStyles = () => ({
  root: {
    alignSelf: "center"
  },
});
const legendLabelStyles = () => ({
  label: {

  },
});
const legendItemStyles = () => ({
  item: {
    alignItems: "baseline"    
  },
});
const legendMarkerStyles = () => ({
  marker: {
    width: "1rem",
    height: "1rem"
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({ classes, ...restProps }) => (
  <Legend.Item className={classes.item} {...restProps} />
);
const legendMarkerBase = ({ classes, ...restProps }) => (
  <Legend.Marker className={classes.marker} {...restProps} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel'})(legendLabelBase);
const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(legendItemBase);
const Marker = withStyles(legendMarkerStyles, { name: 'LegendItem' })(legendMarkerBase);

const PieChart = (props) => {
    return (
        <Chart
          data={props.chartData}
          height={250}
          width={400}
        >
          <PieSeries
            valueField={"val"}
            argumentField={"region"}
            innerRadius={0.87}
          />
          <Animation />
          <Legend position={props.legendPosition} rootComponent={Root} itemComponent={Item} labelComponent={Label} markerComponent={Marker} />
        </Chart>
    )
}

export default PieChart;