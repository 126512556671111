import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    container: {marginTop: "1rem", border: "1px solid #D0D5DE", borderRadius: "12px", justifyContent: "space-around", alignContent: "center"},
    title: { display:"flex", alignItems:"center", fontFamily: 'Avenir Next !important', fontWeight: "500  !important", fontSize: "24px !important", lineHeight: "33px !important", letterSpacing: "0.005em !important", color: "#444C66 !important"}
})

function ContainerWithTitle(props) {
    
    const classes = useStyles()

  return (
    <Box component="span" style={{marginTop: "2rem", padding: "0% 6%", ...props.extraStyles}}>
        <Typography variant='h5' align='left' className={classes.title}>{props.title}</Typography>
        <Grid className={classes.container} style={{width: props.width, height: props.height, border:props.border}} container>
            {props.component}
        </Grid>
    </Box>
  )
}

export default ContainerWithTitle