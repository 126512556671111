import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { Datagrid, DateField, List, ListContextProvider, TextField, useAuthenticated, useDataProvider, useGetList, useList, useListContext } from 'react-admin';
import ContainerWithTitle from '../../UIComponents/Containers/ContainerWithTitle';
import PickupReminder from './OtherComponents/PickupReminder';
import ServicesDemand from './OtherComponents/ServicesDemand';
import ServicesOverview from './OtherComponents/ServicesOverview';
import ServiceStatus from './OtherComponents/ServiceStatus';
import ServiceStatusChart from "./OtherComponents/ServiceStatusChart";
import TodaysService from './OtherComponents/TodaysService';
import reminderIcon from "./assets/cs-reminder.png"
import DetailField from './OtherComponents/DetailField';
import TimeField from '../../UIComponents/CustomSourceFields/TimeField';


const styles = {
  tableRowCell: {fontFamily: 'Avenir Next', fontWeight: "400", fontSize: "18px", lineHeight: "27px", letterSpacing: "0.005em", color: "#262A38"},
  datarid: {overflowY: "scroll",borderRadius: "24px", width: "100%", "& .RaDatagrid-thead": {height: "56px"},}
}

const useStyles = makeStyles({
  container: {padding: "3rem 3rem 2rem 1rem"},
  header: {display: "grid", justifyItems: "start", margin: "1rem 0rem", gap: "1rem"},
  title: {fontWeight: "500", fontSize: "24px", color: "#444C6"},
  info: {display: "flex", width: "866px", height: "60px", background: "#F0F3FB", borderRadius: "12px", alignItems: "center", gap: "1rem", padding: "0rem 1rem", fontWeight: 400, fontSize: "16px", color: "#003399"},
})

export default () => {
    useAuthenticated();
    const classes = useStyles()
    const { data, isLoading } = useGetList(
        'dealer-brands/1/home/',
        {filter: {queryParams: ""}}//?booking-date=2022-07-20 16:22:03 PM
    );
    const listContext = useList({ data, isLoading });
    const Icon = (props) => <img src={props.src} alt="icon" /> 
    return !isLoading ? (
      <div className={classes.container}>
        <ServiceStatus stats={data && data[0].stat} />
        <TodaysService />
        <div style={{display: "flex", gap: "1rem"}}>
        <ContainerWithTitle title={<>Pick up Reminder <Icon src={reminderIcon} /></>} extraStyles={{padding: "0% 1% 0% 0%", width: "65%"}} height="350px" component={
          <ListContextProvider value={{...listContext, data: listContext.data && listContext.data[0].bookings}}>
            {/* <Datagrid stickyHeader={true} sx={{...styles.datarid, height: "350px", "& .RaDatagrid-headerCell": styles.tableRowCell}} rowStyle={() => ({height: "56px", })}>
              <TextField style={styles.tableRowCell} source="pickup_driver" label="On Duty" />
              <DetailField style={styles.tableRowCell} pickupFrom="name" pickupAddress="pickup_location" label="Pickup Details" />
              <TimeField source="pickup_date_time"  label="Time" />
              <TextField style={styles.tableRowCell} source="status" label="Status"/>
          </Datagrid> */}
        </ListContextProvider>
        } />
          <ServiceStatusChart stats={data && data[0].stat} />
        </div>
        <div style={{display: "none", gap: "2rem"}}>
          <ServicesOverview/>
          <ServicesDemand />
        </div>
      </div>
    ) : null
};