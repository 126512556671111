import React from "react"
import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useDispatch, useSelector } from "react-redux";
import { setShareScreenConfig } from "../../store/reducer1";
import { useNotify } from "react-admin";

const useStyles = makeStyles({
    mainContainer: { position: "fixed", top: 0, left: 0, zIndex: "100", height: "100vh", width: "100vw", backdropFilter: "blur(2px)", backgroundColor:"rgba(247,247,247,0.1)" },
    centerAlign: { display: "flex", flexDirection: "column", alignItems: 'center' },
    bottomLine: { width: "4rem", border: "1px solid black", marginTop: "0.8rem", padding: "0.15rem", borderRadius: "20px" },
    shareComponentsContainer: { padding: "6px", width: "25%", height: "5rem" },
    iconName: { fontWeight: "500", fontSize: "0.8rem" }
})


export const ShareScreen = () => {

    const classes = useStyles();
    const notify = useNotify();
    const dispatch = useDispatch()
    const [isUnmounting, setIsUnmounting] = React.useState(false);
    const shareScreenRequiredInfo = useSelector(state => state.store1Reducer.shareScreenConfig.shareScreenRequiredInfo);

    const handleShareClose = () => {
        dispatch(setShareScreenConfig({
            renderShareScreen: false,
            shareScreenRequiredInfo: {}
        }))
    }

    const handleCopyShareMessage = () => {
        const element = document.createElement("textarea");
        element.value = shareScreenRequiredInfo.message;
        document.body.appendChild(element);
        element.select();
        document.execCommand("copy");
        document.body.removeChild(element);
        notify("📋 Copied to clipboard")
    };

    React.useEffect(() => {
        return () => {
            setIsUnmounting(true);
        };
    }, []);

    return (
        <Box className={`fade-in-element mainContainer ${isUnmounting ? 'fade-out' : ''} ${classes.mainContainer}`} onClick={() => handleShareClose()} >
            <Box onClick={(event) => event.stopPropagation()} className={`${classes.centerAlign}`} sx={{ width: "100%", height: "4rem", textAlign: "center", flexDirection: "row", backgroundColor: "rgba(232,234,241)" }} >
                <Box sx={{ paddingLeft: "1rem" }} onClick={() => handleShareClose()} ><ArrowBackIosNewIcon /></Box>
                <Box sx={{ width: '100vw', position: 'absolute' }}><h3>Share</h3></Box>
            </Box>

            <Box onClick={(event) => event.stopPropagation()} className={classes.centerAlign} sx={{ position: 'fixed', bottom: 0, paddingBottom:"1.5rem", width: "100%", height: "fit-content", textAlign: "center", backgroundColor: "rgba(232,234,241)", borderTopLeftRadius:"20px", borderTopRightRadius:"20px" }} >
                <Box className={classes.bottomLine} ></Box>
                <Box className={classes.centerAlign} sx={{ width: "100%", padding: "1rem", paddingTop: "2rem", flexDirection: "row", flexWrap: "wrap" }} >
                    <Box className={classes.shareComponentsContainer} onClick={handleCopyShareMessage} >
                        <Box> <ContentCopyIcon fontSize="large" /></Box>
                        <Box className={classes.iconName} >Copy link</Box>
                    </Box>
                    <Box className={classes.shareComponentsContainer} onClick={() => handleShareWhatsApp(shareScreenRequiredInfo)}>
                        <Box> <WhatsAppIcon fontSize="large" /></Box>
                        <Box className={classes.iconName} >WhatsApp</Box>
                    </Box>
                    <Box className={classes.shareComponentsContainer} onClick={() => handleSendSMS(shareScreenRequiredInfo)}>
                        <Box> <ChatBubbleOutlineRoundedIcon fontSize="large" /></Box>
                        <Box className={classes.iconName} >Message</Box>
                    </Box>
                    <Box className={classes.shareComponentsContainer} onClick={() => handleSendEmail(shareScreenRequiredInfo)}>
                        <Box> <EmailOutlinedIcon fontSize="large" /></Box>
                        <Box className={classes.iconName} >Email</Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}


export const handleShareWhatsApp = (shareScreenRequiredInfo) => {
    window.open(`https://wa.me/+91${shareScreenRequiredInfo.phoneNumber}?text=${encodeURIComponent(shareScreenRequiredInfo.message)}`, '_blank');
}

export const handleSendEmail = (shareScreenRequiredInfo) => {
    const recipientEmail = shareScreenRequiredInfo.email;
    const subject = shareScreenRequiredInfo.mailSub || null;
    const body = shareScreenRequiredInfo.message;

    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.open(mailtoLink);
}

export const handleSendSMS = (shareScreenRequiredInfo) => {
    const recipientNumber = shareScreenRequiredInfo.phoneNumber
    const message = shareScreenRequiredInfo.message;

    let checkiOS = /iPad|iPhone|MacIntel|iPod/.test(window.navigator.platform);


    let smsLink;
    if (checkiOS) {
        smsLink = `sms:+91${recipientNumber}&body=${encodeURIComponent(message)}`;
    } else {
        smsLink = `sms:+91${recipientNumber}?body=${encodeURIComponent(message)}`;
    }

    window.open(smsLink);
};