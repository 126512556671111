import React from 'react'
import ContainerWithTitle from '../../../UIComponents/Containers/ContainerWithTitle'
import HorizontalBarChart from '../../../UIComponents/Charts/HorizontalBarChart'


function ServicesDemand() {
  return (
    <ContainerWithTitle title="Services Demand" height="350px" extraStyles={{padding: "0% 0% 0% 0%", width: "40%"}} component={
        <HorizontalBarChart />
    } />
  )
}

export default ServicesDemand