import { useDispatch } from "react-redux";
import { makeAPICall } from "../../action/APIClient/APIClient";
import { setIsGuestLoggedIn } from "../../store/reducer1";
import { useLogin, useNotify } from "react-admin";
import { useNavigate } from "react-router-dom";


const useLoginToThePortalHook = () => {

    const login = useLogin();
    const notify = useNotify();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const loginToThePortal = (loginCred) => {

        login(loginCred)
            .then(() => {
                makeAPICall("GET", "accounts/user/profile/", null, true)
                    .then((response) => {
                        localStorage.setItem("brand", response[0].brand);

                        let userDetails;

                        if (response[0].user.first_name || response[0].user.last_name) {
                            userDetails = {
                                fullName: response[0].user.last_name ? response[0].user.first_name + " " + response[0].user.last_name : response[0].user.first_name ? response[0].user.first_name : null,
                                avatar: response[0].photo || null,
                                role: response[0].role || null,
                                dealership_name: "Auto Care"
                            }
                        } else {
                            userDetails = {
                                avatar: response[0].photo || null,
                                role: response[0].role || null,
                                dealership_name: "Auto Care"
                            }
                        }
                        dispatch(setIsGuestLoggedIn(true));
                        localStorage.setItem("userDetails", JSON.stringify(userDetails))
                        navigate("/");
                    })
                    .catch((err) => console.log("Error getting profile details" + JSON.stringify(err)))
            })
            .catch(() =>
            notify('Invalid email or password')
            );
    }

    return loginToThePortal;
};

export default useLoginToThePortalHook