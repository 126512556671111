import React from "react";
import { ListQuotation } from "./listQuotation";
import { Box } from "@mui/material";
import { CreateQuotation } from "./createQuotation";
import { SuccessQuotationSharePage } from "./successQuotation";
import { useAuthenticated } from "react-admin";

export const Quotation = () => {

    useAuthenticated();
    const [showCreateQuotation, setShowCreateQuotation] = React.useState(false);
    const [quotationCreate, setQuotationCreate] = React.useState({
        quotationCreateSuccess: false,
        share_url: null,
        email: "",
        phone: "",
        vehicle_model: ""
    });

    return (
        <Box sx={{ width: '100%', maxHeight: "100vh", padding: "0.5rem", paddingTop: "2.5rem", fontSize: "0.9rem" }}>
            {
                showCreateQuotation ?
                    <CreateQuotation goBack={() => setShowCreateQuotation(false)} quotationCreateSuccess={(quotation) => { setShowCreateQuotation(false); setQuotationCreate({ quotationCreateSuccess: true, share_url: quotation.share_url, phone: quotation.phone, email: quotation.email, vehicle_model: quotation.vehicle_model }) }} /> :
                    <ListQuotation openCreateQuotation={() => setShowCreateQuotation(true)} />
            }

            {quotationCreate.quotationCreateSuccess && <SuccessQuotationSharePage goBack={() => setQuotationCreate({ quotationCreateSuccess: false, share_url: null, phone: "", phone: "", vehicle_model:"" })} quotationUserInfo={quotationCreate} /> }
        </Box>
    )
}