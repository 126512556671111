import { Grid } from '@mui/material'
import React from 'react'
import upArrow from "../assets/cs-ss-up-arrow.png"
import downArrow from "../assets/cs-ss-down-arrow.png"
import StatusContainer from "./StatusContainer"
import ContainerWithTitle from '../../../UIComponents/Containers/ContainerWithTitle'

function ServiceStatus(props) {
    
    const Icon = (props) => <img src={props.src} alt="icon" /> 
  return (
    <ContainerWithTitle extraStyles={{padding: "0% 4% 0% 0%"}} height="182px" title="Service Status" component={
      props.stats && <>
            <Grid item><StatusContainer showDivider={true} value={props.stats.total_bookings} description="Increase by 10%" icon={<Icon src={upArrow} />} textColor="#7E98ED" title="New Services" /></Grid>
            <Grid item><StatusContainer showDivider={true} value={props.stats.service_in_progress} description="10 new services added" title="Ongoing Services" textColor="#F08A34"/></Grid>
            <Grid item><StatusContainer showDivider={true} value={props.stats.completed} description="Completion rate is 12%" title="Completed Services" textColor="#68C3A8"/></Grid>
            <Grid item><StatusContainer showDivider={false} value={props.stats.service_paused} description="Decrease rate is 2%" icon={<Icon src={downArrow} />} textColor="#FF5151" title="Delayed Services" /></Grid>
        </>
    } />
  )
}

export default ServiceStatus