import { Menu, useGetList, useResourceDefinitions, useSidebarState } from 'react-admin';

import { Box, Typography } from '@mui/material';
import dashboardIcon from "./assets/cs-dashboard.png"
import ordersIcon from "./assets/cs-orders.png"
import trackingIcon from "./assets/cs-tracking.png"
import servicesIcon from "./assets/cs-services.png"
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import WidgetsIcon from '@mui/icons-material/Widgets';

const useStyles = makeStyles({
    menuContainer: { background: "#fff", height: "100%", borderRight: "1px solid #D0D5DE" },
    submenuTitle: { textAlign: "left", padding: "15px", textTransform: "uppercase", fontFamily: 'Avenir Next !important', fontWeight: "500 !important", fontSize: "17px !important", lineHeight: "27px !important", letterSpacing: "0.005em !important", color: "#757A89 !important" },
    menuItem: { fontFamily: 'Avenir Next !important', fontWeight: "400 !important", fontSize: "18px !important", color: "#282C3F !important" },
    errorBox: { display: 'flex', justifyContent: "center", width: "100%", height: '100%', fontSize: "1rem", paddingTop: "2rem", textAlign: 'center', color: "#282C3F !important" }
})

export const MyMenu = (props) => {
    const classes = useStyles();
    const resources = useResourceDefinitions();
    const [open] = useSidebarState();
    const sideBarMenuItems = useSelector(state => state.store1Reducer.sideBarMenuItems);

    const Icon = (props) => <img src={props.src} alt="icon" />

    return (
        <Menu className={classes.menuContainer} >
            {/* <Menu.Item className={classes.menuItem} to="/dashboard" primaryText="Dashboard" leftIcon={<Icon src={dashboardIcon} />}/>
            <Menu.Item className={classes.menuItem} to="/services" primaryText="Services" leftIcon={<Icon src={servicesIcon} />}/>
            <Menu.Item className={classes.menuItem} to="/tracking" primaryText="Tracking" leftIcon={<Icon src={trackingIcon}/>}/>
            <Menu.Item className={classes.menuItem} to="/promos" primaryText="Promo" leftIcon={<Icon src={trackingIcon}/>}/>
            <Menu.Item className={classes.menuItem} to="/vehicle-list" primaryText="Vehicle Dashboard" leftIcon={<Icon src={trackingIcon} />}/>
            <Menu.Item className={classes.menuItem} to="/quotation" primaryText="Quotation" leftIcon={<Icon src={trackingIcon} />}/>
            <Menu.Item className={classes.menuItem} to="/orders" primaryText="Orders" leftIcon={<Icon src={ordersIcon} />}/> */}
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", color: "#2196f3", fontSize: "1.2rem" }}>
                {open && <Box sx={{ display: 'flex', width: "100%", alignItems: 'center', paddingLeft: "0.6rem", paddingTop: "1rem" }}>
                    <WidgetsIcon sx={{ marginRight: "0.5rem" }} />
                    <h3>Admin Panel</h3>
                </Box>}

                {sideBarMenuItems && sideBarMenuItems.length > 0 ? <Box sx={{ paddingTop: '1rem' }}>
                    {
                        sideBarMenuItems.map((menuitems, index) => {
                            if (menuitems.name == "Quotation") {
                                return <Menu.Item key={index} className={classes.menuItem} to="/quotation" primaryText="Quotation" leftIcon={<Icon src={trackingIcon} />} />
                            } else if(menuitems.name == "Gateway Pass") {
                                return <Menu.Item key={index} className={classes.menuItem} to="/gatewayPass" primaryText="Gateway Pass" leftIcon={<Icon src={servicesIcon} />} />
                            }
                        })
                    }
                </Box>
                    : open && <Box className={classes.errorBox}>
                        <h4>Something Went Wrong :(</h4>
                    </Box>
                }
            </Box>
        </Menu>
    );
};

// Use below to render the resources from App.js
/* {Object.keys(resources).map(name => (
            <Menu.Item
                key={name}
                to={`${name}`}
                primaryText={(resources[name].options && resources[name].options.label) || name}
                leftIcon={resources[name].icon}
                className={classes.menuItem}
            />
        ))}
         <Menu> */