import axios from "axios";

export const url = "https://api.accessai.co:5002"
let token = JSON.parse(localStorage.getItem("authToken")) ? JSON.parse(localStorage.getItem("authToken")).key : null
export const tokenHeader = {Authorization: `Token ${token}`}

export const makeAPICall = async (requestType, api, body, requestHeaders, callback) => {

  let token = JSON.parse(localStorage.getItem("authToken")) ? JSON.parse(localStorage.getItem("authToken")).key : null
  const tokenHeader = {Authorization: `Token ${token}`}

  return new Promise((resolve, reject) => {
    axios({
      method: requestType,
      url: `${url}/${api}`,
      data: body,
      headers: requestHeaders && {...requestHeaders, ...tokenHeader}
    })
    .then(response => resolve(response.data))
    .catch(({response = {}}) => reject(response.data))
  })
}