import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Datagrid, List, ReferenceInput, SelectInput, TextField, TextInput, useAuthenticated, useListContext, useList, useGetList, ListContextProvider } from 'react-admin';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TablePagination } from '@mui/material';
// const filters = [
//   <TextInput source="q" label="Search" alwaysOn />,
//   <ReferenceInput source="name" label="Name" reference="name">
//       <SelectInput optionText="name" />
//   </ReferenceInput>,
// ];

const styles = {
  tableRowCell: {fontFamily: 'Avenir Next', fontWeight: "400", fontSize: "18px", lineHeight: "27px", letterSpacing: "0.005em", color: "#262A38"},
  datarid: {overflowY: "scroll",border: "1px solid #D0D5DE",borderRadius: "24px","& .RaDatagrid-thead": {height: "56px"},}
}

const useStyles = makeStyles({
  container: {padding: "1rem 0rem"},
  header: {display: "grid", justifyItems: "start", margin: "1rem 0rem", gap: "1rem"},
  title: {fontWeight: "500", fontSize: "24px", color: "#444C6"},
  info: {display: "flex", width: "866px", height: "60px", background: "#F0F3FB", borderRadius: "12px", alignItems: "center", gap: "1rem", padding: "0rem 1rem", fontWeight: 400, fontSize: "16px", color: "#003399"},
})

export default () => {
    useAuthenticated();
    const classes = useStyles()
    const [currentPage, setCurrentPage] = useState(0)
    // const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
      console.log("newPage",newPage);
      // setPage(newPage);
      // if(newPage != 0)
      setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setCurrentPage(0);
    };

    const { data, isLoading } = useGetList(
        `service-bookings/?page=${currentPage + 1}`,
    );
    const listContext = useList({ data, isLoading });
    return (
      <div className={classes.container}>
        <header className={classes.header}>
          <h1 className={classes.title}>Orders</h1>
          <div className={classes.info}>
            <InfoOutlinedIcon />
            <p>You can check all your orders here and update the status</p>
          </div>
        </header>
        <ListContextProvider value={{...listContext, data: listContext.data && listContext.data[0].results}}>
            <Datagrid stickyHeader={true} sx={{...styles.datarid, "& .RaDatagrid-headerCell": styles.tableRowCell}} rowStyle={() => ({height: "56px", })}>
                <TextField source="name" label="Name" />
                <TextField source="vehicle.name" label="Car" />
                <TextField source="service.name" label="Service" />
                <TextField source="staff" label="Assigned Staff" />
                <TextField source="status.booking" label="Order Id"/>
                <TextField source="status.status_str" label="Status"/>
            </Datagrid>
        </ListContextProvider>
        <TablePagination
          component="div"
          count={listContext.data && listContext.data[0].count}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2,5,10]}
        />
      </div>
    );
};