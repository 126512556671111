import './App.css';
import * as React from "react";
import { Admin, Resource, CustomRoutes } from 'react-admin';
import Login from './Apps/Authantication/Login';
import {authProvider} from "./Apps/Authantication/AuthProvider"
import SignUp from './Apps/Authantication/SignUp';
import { Route } from 'react-router-dom';
import { dataProvider } from './action/Providers/dataProvider';
import Services from './Apps/Services/Services';
import Tracking from './Apps/Tracking/Tracking';
import Orders from './Apps/Orders/Orders';
import Dashboard from "./Apps/Dashboard/Dashboard";
import { MyLayout } from './Apps/Layout/Layout';
// import servicesIcon from "./Apps/Layout/assets/cs-services.png"
// import couponsIcon from "./Apps/Layout/assets/cs-coupons.png"
// import trackingIcon from "./Apps/Layout/assets/cs-tracking.png"
// import PromoList from "./Apps/Promos/List.js"
import ordersIcon from "./Apps/Layout/assets/cs-orders.png"
import Promos from './Apps/Promos/Promos';
import PromoCreate from "./Apps/Promos/Create"
import { OrderProfileContainer } from './Apps/Layout/components/bookingOrderProfile.jsx/orderProfileContainer';
import VehicleListing from './Apps/VehicleList/VehicleListing/VehicleListing';
import { Quotation } from './Apps/Quotation/quotation';
import { AppStart } from './Apps/App2';
import { fetchSidebarMenuItems } from './Data/fetchSideBarMenu';
import { useDispatch, useSelector } from 'react-redux';
import { GatewayPass } from './Apps/Gatepass/gatewayPass';
import { ShareScreen } from './Apps/share/share';



function App() {

  const Icon = (props) => <img src={props.src} alt="icon" /> 
  const dispatch = useDispatch();
  const isGuestLoggedIn = useSelector(state => state.store1Reducer.isGuestLoggedIn);
  const renderShareScreen = useSelector(state => state.store1Reducer.shareScreenConfig.renderShareScreen);

  React.useEffect(() => {
    fetchSidebarMenuItems(dispatch);
  },[isGuestLoggedIn])

  return (
    <div className="App">
      <Admin layout={MyLayout} dashboard={AppStart} dataProvider={dataProvider} loginPage={<Login />} authProvider={authProvider}>
        
        {/* <Resource options={{label: "Dashboard"}} name="home" list={<Dashboard />} icon={<Icon src={ordersIcon} />} /> */}
        {/* <Resource options={{label: "Orders"}} name="service-bookings/?page=2" list={<Orders />} icon={<Icon src={ordersIcon} create={<VehicleListing/>} />} /> */}
        {/* <Resource options={{label: "Services"}} name="services" list={<Services />} icon={<Icon src={servicesIcon} />} /> */}
        {/* <Resource options={{label: "Tracking"}} name="/tracking" list={<Tracking />} icon={<Icon src={trackingIcon} />} />
        <Resource options={{label: "Coupons"}} name="/coupon" list={<Tracking />} icon={<Icon src={couponsIcon} />} /> */}

        <CustomRoutes noLayout>
            <Route path="/sign_up" element={<SignUp />} />
            <Route path="/promoCreate" element={<PromoCreate />} />
        </CustomRoutes>
        <CustomRoutes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/services" element={<Services />} />
            <Route path="/vehicle-list" element={<VehicleListing />} />
            <Route path="/quotation" element={< Quotation />} />
            <Route path="/gatewayPass" element={< GatewayPass />} />
            <Route path="/tracking" element={<Tracking />} />
            <Route path="/promos" element={<Promos />} />
            <Route path='/orderProfile/:id' element={<OrderProfileContainer />} />
        </CustomRoutes>
      </Admin>

      { renderShareScreen && <ShareScreen />}
    </div>
  );
}

export default App;
