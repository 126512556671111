import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, CircularProgress, Pagination, Rating, Stack, Typography } from "@mui/material";
import { HeaderInformationContainer } from "../../../../UIComponents/Containers/HeaderInformationContainer";
import { useGetList, useNotify } from "react-admin";
import { makeAPICall } from "../../../../action/APIClient/APIClient";
import { UpdateServiceBookingWeb } from "../updateBookingStatus.jsx/updateServiceBookingWeb";
import { useNavigate } from "react-router-dom";
import { BookingStatus } from "../bookingStatus/bookingStatus";

const useStyles = makeStyles({
    container: { padding: "1rem", width: "75vw", minWidth: "800px", margin: "auto", border: "1px solid #b5b5b5", borderRadius: "12px" },
    childContainer: { display: "flex", margin: "1rem" },
    centerElements: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center' },
    pageButtonContainer: { display: 'flex', minWidth: "800px", margin: "1.5rem", marginRight: "2.5rem", justifyContent: 'flex-end', },
    normalText: { margin: "0.8rem", fontWeight: "430", fontSize: "16.5px" },
    mediumText: { margin: "1.2rem", fontSize: "1.1rem", fontWeight: "430", letterSpacing: "0.005em" },
    profileImage: { width: "170px", borderRadius: "50%" },
    carImage: { width: "100%", maxHeight: "250px", objectFit: "cover", margin: 'auto', borderRadius: "12px", marginBottom: "1rem" },
    serviceImage: { width: "100%", borderTopRightRadius: "18px", borderBottomRightRadius: "18px" },
    colorHeading: { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: "18px", color: "white", fontWeight: "800", marginLeft: "-1rem", marginTop: "4rem", marginBottom: "3rem", width: "300px", height: "40px", backgroundColor: "#a783de", borderTopRightRadius: "6px", borderBottomRightRadius: "6px" },
    colorLable: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontSize: "18px", fontWeight: "800", marginTop: "-1rem", marginBottom: "2rem", width: "180px", height: "30px", backgroundColor: "#e6b35c", borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px" }
})


export const OrderProfile = () => {

    const classes = useStyles();
    const notify = useNotify();
    const navigate = useNavigate();
    const brand = localStorage.getItem("brand");
    const bookingId = window.location.hash.split("/")[2];
    const [rating, setRating] = React.useState(null)
    const [status, setStatus] = React.useState([])
    const [vehicleImage, setVehicleImage] = React.useState([]);
    const [selectedVehicleImage, setSelectedVehicleImage] = React.useState(0)

    const { data, isLoading, isError, error, refetch } = useGetList(
        `dealer-brands/${brand}/service-bookings/${bookingId}/`
    );

    const handleVehicleImage = (event, value) => {
        setSelectedVehicleImage(value - 1);
    };

    const getStatus = () => {
        makeAPICall("GET", `auto-care/service-bookings/${bookingId}/status/`, null, true)
            .then((response) => setStatus(response))
            .catch((err) => console.log("Error getting profile details" + JSON.stringify(err)))
    }

    const getRating = (rating, rating_out_of) => {
        const percentage = (rating / rating_out_of) * 100;
        const ratingScale = 5;
        const ratingRange = 100 / ratingScale;
        let ratingValue = Math.round(percentage / ratingRange);

        ratingValue = Math.max(0, ratingValue);
        ratingValue = Math.min(5, ratingValue);

        return ratingValue;
    }

    const updateServiceBooking = (msg) => {
        notify(msg);
        getStatus();
        refetch();
    }

    const handlePagePrev = () => {
        navigate(`/orderProfile/${bookingId - 1}`)
    }

    const handlePageNext = () => {
        navigate(`/orderProfile/${+bookingId + 1}`)
    }

    React.useEffect(() => {

        getStatus();

        const defaultVehicleImage = "https://img.freepik.com/premium-vector/car-logo-icon-emblem-template-design-vector-illustration_510486-174.jpg"
        if (data && data[0]) {
            setRating(getRating(data[0].service.rating, data[0].service.rating_out_of))

            if (data[0].vehicle.vehicle_photos.length > 0) {
                setVehicleImage(data[0].vehicle.vehicle_photos)
            } else {
                setVehicleImage([defaultVehicleImage])
            }
        }
    }, [data])

    return !isLoading && !isError && data[0] ? (
        <div>
            <HeaderInformationContainer heading={"Orders"} msg={"You can check detailed view of orders here like order ID, Customer Name, etc"} />

            <div className={classes.pageButtonContainer}>
                <Button onClick={handlePagePrev} variant="outlined" color="inherit" style={{ marginRight: "1rem" }} >Prev</Button>
                <Button onClick={handlePageNext} variant="outlined" color="inherit">Next</Button>
            </div>

            <Box className={classes.container}>

                <Box className={classes.childContainer} >
                    <Box className={classes.centerElements} style={{ width: "20rem" }}>
                        <img className={classes.profileImage} src={data[0].user.img || "https://previews.123rf.com/images/apoev/apoev2107/apoev210700033/171405527-default-avatar-photo-placeholder-gray-profile-picture-icon-business-man-illustration.jpg"} />
                    </Box>
                    <Box style={{ width: "80rem", padding: "1rem" }}>
                        <Typography variant="h5" align="left" style={{ margin: "auto", paddingLeft: "1rem" }} >Order ID - {data[0].id}</Typography>
                        <Box className={classes.childContainer} style={{ width: "100%", justifyContent: 'space-between', paddingLeft: "1rem", paddingRight: "1rem" }} >
                            <Box style={{ textAlign: 'left' }} >
                                <Box className={classes.normalText}>Customer - {data[0].user.first_name + " " + data[0].user.last_name} </Box>
                                <Box className={classes.normalText}>Pincode - 452001</Box>
                                <Box className={classes.normalText}>City - Indore</Box>
                            </Box>
                            <Box style={{ textAlign: 'left' }}>
                                <Box className={classes.normalText}>Address - {data[0].delivered_at || "N/A"}</Box>
                                <Box className={classes.normalText}>Contact - 09691363755</Box>
                                <Box className={classes.normalText}>Payment - {data[0].payment_mode}</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <hr />

                <Box>
                    <Box className={classes.colorHeading} >Vehicle Details</Box>

                    <Box className={classes.childContainer}>
                        <Box className={classes.centerElements} style={{ width: "35rem" }}>
                            <img className={classes.carImage} src={vehicleImage[selectedVehicleImage]} />
                            <Stack spacing={2} sx={{ paddingBottom: "1rem" }} >
                                <Pagination sx={{ marginX: 'auto' }} count={vehicleImage.length} page={selectedVehicleImage + 1} onChange={handleVehicleImage} />
                            </Stack>
                        </Box>
                        <Box style={{ width: "65rem" }}>
                            <Box className={classes.childContainer} style={{ width: "94%", margin: "auto", padding: "1rem", justifyContent: 'space-between' }} >
                                <Box style={{ textAlign: 'left' }} >
                                    <Box className={classes.mediumText}>Car - {data[0].vehicle.name} </Box>
                                    <Box className={classes.mediumText}>Car Model - {data[0].vehicle.model || "N/A"}</Box>
                                    <Box className={classes.mediumText}>Fuel - Ethanol</Box>
                                </Box>
                                <Box style={{ textAlign: 'left' }}>
                                    <Box className={classes.mediumText}>Engine - Best</Box>
                                    <Box className={classes.mediumText}>Transmission - what's this?</Box>
                                    <Box className={classes.mediumText}>Mileage - You can't even imagine</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <hr />

                <Box style={{ marginBottom: "2rem" }} >
                    <Box className={classes.colorHeading} >Service Details</Box>

                    <Box className={classes.childContainer} style={{ width: "90%", maxHeight: "250px", margin: "0rem", marginLeft: "auto", marginRight: "0rem", marginBottom: "2rem", borderRadius: "18px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }} >
                        <Box style={{ width: "60rem", padding: "1rem", paddingLeft: "4rem", margin: 'auto' }}>
                            <Box className={classes.colorLable}>Popular</Box>
                            <Box>
                                <Typography variant="h4" align="left">Normal</Typography>
                                <ul style={{ textAlign: 'left' }} >
                                    <li className={classes.normalText} >Basic Maintainence</li>
                                    <li className={classes.normalText}>Basic Changes and Repair</li>
                                </ul>
                                <Typography variant="h5" align="left" style={{ paddingTop: "0.2rem" }} >Rs 4000/-</Typography>
                            </Box>
                        </Box>

                        <Box className={classes.centerElements}>
                            <img className={classes.serviceImage} style={{ width: "400px", height: "100%" }} src="https://okcredit-blog-images-prod.storage.googleapis.com/2021/02/automobilerepair1-1.jpg" />
                        </Box>
                    </Box>

                    <Box className={classes.childContainer} sx={{ marginY: "4rem", position: "relative" }}>
                        <Box style={{ padding: "2rem", paddingRight: "4rem", borderRight: "2px solid #b5b5b5" }}>
                            <Typography variant="h5" sx={{ fontSize: '1.5rem' }} align="left">Status</Typography>
                        </Box>
                        <Box className={classes.childContainer} style={{ justifyContent: "center", width: "80%", margin: 'auto', marginTop: "2rem", marginBottom: 0 }}>
                            { status.length > 0 && <BookingStatus status={status} callback={updateServiceBooking} />}
                        </Box>
                    </Box>

                    <Box className={classes.childContainer} style={{ margin: "2rem" }}>
                        <Box style={{ padding: "2rem", paddingRight: "4rem" }}>
                            <Typography variant="h5" sx={{ fontSize: '1.5rem' }} align="left">Rating</Typography>
                        </Box>
                        <Box className={classes.childContainer} style={{ alignItems: "center", justifyContent: "center" }} >
                            <Rating name="read-only" value={rating} sx={{ fontSize: "2.5rem" }} size="large" readOnly />
                            <Typography variant="h5" sx={{ fontSize: '1.7rem', paddingX: "2rem", paddingTop: '0.2rem' }} align="left">{data[0].service.rating + " / " + data[0].service.rating_out_of}</Typography>
                        </Box>
                    </Box>

                    <Box className={classes.childContainer} style={{ margin: "2rem" }}>
                        <Box style={{ padding: "2rem", paddingRight: "1rem", width: "25rem" }}>
                            <Typography variant="h5" sx={{ fontSize: '1.5rem' }} align="left">Employee Note</Typography>
                        </Box>
                        <Box className={classes.childContainer} style={{ width: "75rem", alignItems: "center", justifyContent: "center" }} >
                            <Box style={{ width: "100%", margin: "auto", minHeight: "60px", border: "1px solid #b5b5b5", borderRadius: "12px", padding: "0.8rem", fontSize: "17px", textAlign: "left" }} >Something note worthy</Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    ) : isError ?
        <div>
            <div className={classes.pageButtonContainer} style={{ marginTop: "3rem", marginBottom: "0.5rem" }} >
                <Button onClick={handlePagePrev} variant="outlined" color="inherit" style={{ marginRight: "1rem" }} >Prev</Button>
                <Button onClick={handlePageNext} variant="outlined" color="inherit">Next</Button>
            </div>

            <div className={classes.container} style={{ display: "flex", flexDirection: "column", height: "90vh", marginTop: "5vh", justifyContent: 'center', alignItems: "center" }}>
                <Typography variant='h4' fontWeight={800} color="#8a787a" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Something Went Wrong :(</Typography>
                {error.detail && <Typography variant='h5' fontWeight={800} color="#828282" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Error Details : {error.detail}</Typography>}
            </div>
        </div>
        :
        (
            <div>
                <div className={classes.pageButtonContainer} style={{ marginTop: "3rem", marginBottom: "0rem" }} >
                    <Button onClick={handlePagePrev} variant="outlined" color="inherit" style={{ marginRight: "1rem" }} >Prev</Button>
                    <Button onClick={handlePageNext} variant="outlined" color="inherit">Next</Button>
                </div>
                <div className={classes.container} style={{ display: "flex", flexDirection: "column", height: "90vh", marginTop: "5vh", justifyContent: 'center', alignItems: "center" }}>
                    <CircularProgress sx={{ color: "#828282" }} />
                    <br />
                    <Typography variant='h5' fontWeight={800} color="#828282" align='center' marginTop={"0.8rem"} paddingLeft="1rem" >Loading...</Typography>
                </div>
            </div>
        )
}