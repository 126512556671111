import React from 'react'
import BarChart from '../../../UIComponents/Charts/BarChart'
import ContainerWithTitle from '../../../UIComponents/Containers/ContainerWithTitle'

function ServicesOverview() {

  return (
    <ContainerWithTitle title="Services Overview" height="350px" extraStyles={{padding: "0% 0% 0% 0%", width: "65%"}} component={
        <BarChart />
    }/>
  )
}

export default ServicesOverview