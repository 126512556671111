import React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import VehicleListingForm from './VehicleListForm';

const useStyles = makeStyles({
  mainContainer: { display: "flex", justifyContent: 'center', alignItems: "center", width: "100%", margin: 'auto',},

})

function VehicleListing() {

  const theme = useTheme();
  const classes = useStyles();

  const [vehicleListInfo, setVehicleListInfo] = React.useState({
    name: "",
    modal: "",
    fuel: [],
    dealershipId: "",
    manufactureYear: new Date().getFullYear(),
    transmission: "",
    colorCode: [],
    mileage: "",
    engine: [],
    gearBox: "",
    price: "",
    stock: "",
  })

  const handleVehicleInfo = (fieldName, fieldValue) => {
    setVehicleListInfo(prevDetails => ({ ...prevDetails, [fieldName]: fieldValue }))
  }

  const isTab = useMediaQuery(() =>
    theme.breakpoints.down("md")
  );

  React.useEffect(() => {
    console.log(vehicleListInfo)
  }, [vehicleListInfo])

  return (
    <Box className={classes.mainContainer} >

      <Box sx={{ width: isTab ? "100%" : "60%" }} >
        <VehicleListingForm vehicleListInfo={vehicleListInfo} handleVehicleInfo={handleVehicleInfo} />
      </Box>

    </Box>
  )
}

export default VehicleListing