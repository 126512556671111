import React from 'react'
import { Datagrid, List, TextField, useAuthenticated, useListContext } from 'react-admin';

const styles = {
  tableRowCell: {fontWeight: "400", fontSize: "18px", lineHeight: "27px", letterSpacing: "0.005em", color: "#262A38"},
  datarid: {overflowY: "scroll",border: "1px solid #D0D5DE",borderRadius: "24px","& .RaDatagrid-thead": {height: "56px"},}
}

function DataList() {
  const { data } = useListContext();
  return (
    <Datagrid stickyHeader={true} sx={{...styles.datarid, "& .RaDatagrid-headerCell": styles.tableRowCell}} rowStyle={() => ({height: "56px", })} data={data && data[0].results}>
      <TextField source="name" />
    </Datagrid>
  )
}

function Services() {
    useAuthenticated();
    
  return (
    <List >
      <DataList />
  </List>
  )
}

export default Services