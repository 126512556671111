import { makeAPICall } from "../APIClient/APIClient"
import orders from "./MockResponses/Orders.js"

export const dataProvider = {
    getList:    (resource, params) => {  console.log("params",params); return makeAPICall(`GET`, `auto-care/${resource}${params.filter.queryParams ? params.filter.queryParams : ""}`, {...params.requestBody}, {...params.requestHeaders}, () => {}).then(data => Promise.resolve({data: [{id: 1, ...data}], total: Object.keys(data).length}))},
    getOne:     (resource, params) => {  console.log("params",params); return makeAPICall(`GET`, `auto-care/${resource}${params.id ? params.id : ""}/`, {...params.requestBody}, {...params.requestHeaders}, () => {}).then(data => Promise.resolve({data: data}))},
    getMany:    (resource, params) => Promise,
    getManyReference: (resource, params) => Promise,
    create:     (resource, params) => {  console.log("params",params); return makeAPICall(params.meta.requestType, `auto-care/${resource}/`, {...params.data}, {...params.requestHeaders}, () => {}).then(data => Promise.resolve({data: data}))},
    update:     (resource, params) => Promise,
    updateMany: (resource, params) => Promise,
    delete:     (resource, params) => Promise,
    deleteMany: (resource, params) => Promise,
}

// resource == "orders" ? Promise.resolve({data: orders, total: orders.length}) : Promise.resolve() ,//