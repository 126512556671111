import React from "react";
import { fetchSidebarMenuItems } from "../Data/fetchSideBarMenu";
import { useNavigate } from "react-router-dom";
import { useAuthenticated } from "react-admin";
import { useDispatch } from "react-redux";
import { setIsGuestLoggedIn } from "../store/reducer1";


export const AppStart = () => {

    useAuthenticated();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    React.useEffect(() => {

        if(localStorage.getItem("authToken")){
            dispatch(setIsGuestLoggedIn(true));
        } else {
            dispatch(setIsGuestLoggedIn(false));
        }

        navigate("/quotation")
    },[])

    return false;
}