import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    backButton: {background: "#cccbcb", padding: "12px", display: "flex", borderRadius: "50%", width: "3rem", height: "3rem", paddingLeft: "17px", cursor: "pointer"}
  })

function BackButton(props) {
    const classes = useStyles()
  return (
    <span className={classes.backButton} onClick={props.handleBack} ><ArrowBackIosIcon/></span>
  )
}

export default BackButton