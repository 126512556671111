import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react'
import { useLogin, useNotify } from 'react-admin';
import InputWithLabel from '../../UIComponents/Inputs/InputWithLabel'
import googleLogo from "./assets/google-logo.png"
import loginGrapics from "./assets/login-graphics.png"
import GilroyFonts from "./assets/Gilroy-Regular.woff"
import { Link } from 'react-router-dom';
import { makeAPICall } from '../../action/APIClient/APIClient';
import { useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setIsGuestLoggedIn } from '../../store/reducer1';
import useLoginToThePortalHook from './loginToPortalHook';

const useStyles = makeStyles({
    container: {width: "100vw", height: "100%", justifyContent: "space-between", alignContent: "center"},
    item: {width: "50vw", height: "100vh", justifyContent: "space-around", alignContent: "center"},
    item1: {display: "grid", gap: "2rem", justifyItems: "start", marginTop: "-5rem !important"},
    item2: {background: "linear-gradient(180deg, #001A45 0%, #1D3688 140.62%)", padding: "2% 7%"},
    button: {height: "48px", width: "20rem", fontFamily: "Avenir Next !important", fontSize: "21px !important", color: "#001A45 !important", textTransform: "none !important", border: "1.5px solid #001A45 !important", borderRadius: "6px !important"},
    login: {background: "#001A45 !important", borderRadius: "6px !important", color: "#fff !important"},
    h4: {fontFamily: 'Gilroy-Bold', fontSize: "36px", lineHeight: "42px", color: "#FFFFFF"},
    h5: {fontFamily: 'Avenir Next', fontStyle: "normal", fontWeight: 400, fontSize: "24px", lineHeight: "34px", color: "#E5E5E5"},
    form: {display: "grid", gap: "2rem", justifyItems: "center", alignItems:"center", textAlign: "left", margin: "auto"},
    subtitle1: {fontFamily: 'Avenir Next !important', fontWeight: "400 !important", fontSize: "16px !important", lineHeight: "20px !important", textAlign: "center"}
})

function Login() {
    const [loginCreds, setLoginCreds] = useState({})
    const classes = useStyles()

    const notify = useNotify();
    const dispatch = useDispatch();
    const loginToThePortal = useLoginToThePortalHook();
    const theme = useTheme();

    const handleSubmit = e => {
        e.preventDefault();

        const loginCred = {
            "username": loginCreds.email,
            "password": loginCreds.password
        }
        loginToThePortal(loginCred);
    };

    const handleChange = (e) => {
        setLoginCreds({...loginCreds, [e.target.name]: e.target.value})
    }

    const GoogleIcon = () => <img style={{height: "2rem"}} src={googleLogo} alt="google"/>

    const isMobile = useMediaQuery(() =>
        theme.breakpoints.down("md")
    );

    React.useEffect(() => {

        if(sessionStorage.getItem("loginCred")){
            let sessionStorageLoginCreds = JSON.parse(sessionStorage.getItem("loginCred"))
            setLoginCreds({...loginCreds, email: sessionStorageLoginCreds.username, password: sessionStorageLoginCreds.password})
            sessionStorage.removeItem("loginCred");
        }

    },[])

  return !isMobile ? (
    <Grid container className={classes.container}>
        <Grid item className={`${classes.item} ${classes.item1}`}>
            <Typography variant='h4' style={{fontFamily: GilroyFonts, fontWeight: 600, fontSize: "48px", lineHeight: "32px", color: "#001A45", margin:"auto", marginBottom:"5vh"}}>Log In</Typography>
            {/* <Button variant="outlined" className={classes.button} startIcon={<GoogleIcon/>}>Sign up with Google</Button> */}
            <FormGroup className={classes.form}>
                <InputWithLabel name="email" inputType="email" label="Email" placeholder="Enter your email" handleChange={handleChange} error={false} value={loginCreds.email}/>
                <InputWithLabel name="password" inputType="password" label="Password" placeholder="Enter your password" handleChange={handleChange} error={false} value={loginCreds.password}/>
                {/* <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}}>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Remember me" />
                    <p>Forget password?</p>
                </div> */}
                <Button onClick={handleSubmit} className={`${classes.button} ${classes.login}`} variant='contained' type='submit'>Log in</Button>
                <Typography variant='subtitle1' className={classes.subtitle1}>Not yet registered? <Link style={{color: "#2A292E", textDecoration: "none", fontWeight: 600}} to="/sign_up" >Create an Account</Link></Typography>
            </FormGroup>
        </Grid>
        <Grid item className={`${classes.item} ${classes.item2}`}>
            <img src={loginGrapics} height={"70%"} alt="login"/>
            <Typography variant='h4' className={classes.h4}>Connect with your Customers</Typography>
            <Typography variant='h6' className={classes.h5}>Now connecting and providing services to your customers is easy</Typography>
        </Grid>
    </Grid>
  ) : (
    <Grid container className={classes.container}>
        <Box sx={{ marginTop: "10rem" }} >
        <Typography variant='h4' style={{fontFamily: GilroyFonts, fontWeight: 600, fontSize: "48px", lineHeight: "32px", color: "#001A45", margin:"auto", marginBottom:"5vh", width:"100vw"}}>Log In</Typography>
            {/* <Button variant="outlined" className={classes.button} startIcon={<GoogleIcon/>}>Sign up with Google</Button> */}
            <FormGroup className={classes.form} >
                <InputWithLabel name="email" inputType="email" label="Email" placeholder="Enter your email" handleChange={handleChange} error={false} value={loginCreds.email}/>
                <InputWithLabel name="password" inputType="password" label="Password" placeholder="Enter your password" handleChange={handleChange} error={false} value={loginCreds.password}/>
                {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center" }}>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Remember me" />
                    <p>Forget password?</p>
                </div> */}
                <Button onClick={handleSubmit} className={`${classes.button} ${classes.login}`} variant='contained' type='submit'>Log in</Button>
                <Typography variant='subtitle1' className={classes.subtitle1}>Not yet registered? <Link style={{color: "#2A292E", textDecoration: "none", fontWeight: 600}} to="/sign_up" >Create an Account</Link></Typography>
            </FormGroup>
        </Box>
    </Grid>
  )
}

export default Login