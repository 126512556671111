import { Button, Card, CardContent, CardMedia, InputLabel, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import InputWithLabel from '../../UIComponents/Inputs/InputWithLabel'
import BackButton from '../../UIComponents/Buttons/BackButton';
import { useCreate, useGetOne } from 'react-admin';
import homePage from "./assets/homePage.png"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { tokenHeader, url } from '../../action/APIClient/APIClient';

const useStyles = makeStyles({
  label: { marginLeft: "10px", fontFamily: 'Avenir Next', fontWeight: 500, fontSize: "2vmin !important", lineHeight: "31px", color: "#001A45", textAlign: "left" },
  input: { padding: "0% 5%", width: "70vmin", height: "15vmin", fontSize: "2vmin !important", background: "#FFFFFF", border: "1.55642px solid #C4C4C4", borderRadius: "1.3vmin" },
  picker: { padding: "0% 5%", height: "5vmin", fontSize: "2vmin !important", background: "#FFFFFF", border: "1.55642px solid #C4C4C4", borderRadius: "1.3vmin" },
  promoImage: { height: "11vmin", cursor: "pointer", WebkitTapHighlightColor: "transparent" },
  button: { fontFamily: 'Avenir Next', fontSize: "2vmin !important", width: "14vmin !important", height: "5vmin !important", border: "2px solid #1D3688 !important", borderRadius: "12px" }
})

function PromoCreate(props) {
  const [promoDetails, setPromoDetails] = useState({})
  const [startDate, setStartDate] = React.useState('2014-08-18T21:11:54');
  const [endDate, setEndDate] = React.useState('2014-08-18T21:11:54');
  const classes = useStyles()
  const navigate = useNavigate()

  let url = window.location.href
  let searchParams = new URLSearchParams(url.split("?")[1]);
  let promoId = searchParams.get('id')

  const [create, { loading, error }] = useCreate();

  const formatDateHandler = (date) => {

    if (!date) return

    let d = new Date(date).toLocaleString()

    d = date.slice(0, 10)
    let t = date.slice(11, 19)
    // let z = date.slice(,)

    // if(ap.toLowerCase() == "am"){
    //   d = date.split(' AM').join("") 
    // } else {
    //   d =date.split(' PM').join("")
    // }
    return `${d}T${t}`

  }

  const dateForPost = (date) => {
    let fullDate = new Date(date)
    let t = fullDate.toUTCString().slice(17, -4)
    let d = `${fullDate.getFullYear()}-${("0" + (fullDate.getMonth() + 1)).slice(-2)}-${fullDate.getDate()}`

    return `${d} ${t} UTC`

  }

  const handleSubmit = () => {
    console.log("creating promo");
    if (promoId) create(`dealer-brands/1/promos/${promoId}`, {
      data: {
        "name": promoDetails.name,
        "description": promoDetails.description,
        "long_description": promoDetails.long_description,
        "service_center": 1,
        "image_path": "http://api.accessai.co:9001/static/images/promo/promo2.png",
        "start_date": dateForPost(promoDetails.start_date),
        "end_date": dateForPost(promoDetails.end_date)
      }, meta: { requestType: "PATCH" }
    })
    else create(`dealer-brands/1/promos`, {
      data: {
        "name": promoDetails.name,
        "description": promoDetails.description,
        "long_description": promoDetails.long_description,
        "service_center": 1,
        "image_path": "http://api.accessai.co:9001/static/images/promo/promo2.png",
        "start_date": promoDetails.start_date,
        "end_date": promoDetails.end_date

      }, meta: { requestType: "POST" }
    })
  }

  const { data, isLoading } = useGetOne(
    `dealer-brands/1/promos/${promoId}`,
    { id: "" }
  );

  const handleChange = (e) => {
    setPromoDetails({ ...promoDetails, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    setPromoDetails(data)
  }, [data])

  useEffect(() => {
    console.log("useeffect");
    axios.get(`${url}/auto-care/dealer-brands/1/forms/promo/`, {
      ...tokenHeader
    }).then(res => console.log(res))
  }, [])

  function formatDate(date) {
    if (!date) return
    let d = date.slice(0, -6).split(" ")
    return new Date(`${d[0]}T${d[1]}`)
  }

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1.3fr", height: "100vh" }}>
      <div style={{ display: "grid", gap: "1rem", padding: "0rem 5vmin", height: "max-content" }}>
        <Typography
          style={{ fontFamily: 'Avenir Next', textAlign: "left", marginTop: "1rem", fontSize: "2vmin !important", color: "#585C74" }}
          variant='h5'>{promoId ? "Edit Promo" : "Create Promo"}</Typography>
        <InputWithLabel width="70vmin" name="name" inputType="text" label="Name" placeholder="" handleChange={handleChange} error={false} value={promoDetails && promoDetails.name} />
        <InputWithLabel width="70vmin" name="description" inputType="text" label="Description" placeholder="" handleChange={handleChange} error={false} value={promoDetails && promoDetails.description} />
        <div style={{ display: "grid", justifyContent: "flex-start", gap: "0.4rem" }}>
          <InputLabel className={classes.label}>
            Long Description
          </InputLabel>
          <textarea name='long_description' onChange={handleChange} className={classes.input} value={promoDetails && promoDetails.long_description}></textarea>
        </div>
        <div style={{ display: "flex", width: "70vmin", justifyContent: "space-between", gap: "1rem" }}>
          <div style={{ display: "grid", width: "50%", gap: "0.4rem" }}>
            <InputLabel className={classes.label} >Start Date</InputLabel>
            <input name='start_date' onChange={handleChange} value={promoDetails && formatDateHandler(promoDetails.start_date)} className={classes.picker} type={"datetime-local"} />
          </div>
          <div style={{ display: "grid", width: "50%", gap: "0.4rem" }}>
            <InputLabel className={classes.label} >End Date</InputLabel>
            <input name='end_date' onChange={handleChange} value={promoDetails && formatDateHandler(promoDetails.end_date)} className={classes.picker} type={"datetime-local"} />
          </div>
        </div>
        <div style={{ display: "grid", gap: "0.4rem" }}>
          <InputLabel className={classes.label} >Image</InputLabel>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img className={classes.promoImage} src={promoDetails && promoDetails.image} alt="promo" />
            {/* <img className={classes.promoImage} src='https://api.accessai.co:5001/static/images/promo/promo1.png' alt="promo"/> */}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2rem", width: "70vmin" }}>
          <Button onClick={() => navigate(-1)} className={classes.button} style={{ color: "#1D3688" }} variant='outlined'>Cancel</Button>
          <Button className={classes.button} style={{ background: "#1D3688" }} onClick={handleSubmit} variant='contained'>Done</Button>
        </div>
      </div>
      <div style={{ display: "grid", gap: "1rem", justifyContent: "center", justifyItems: "center", padding: "0rem 5vmin", background: "#E5E5E5", gridTemplateRows: "1fr 1.6fr" }}>
        <Card sx={{ position: "relative", display: "flex", background: "transparent", border: "none", boxShadow: "none", width: "80vmin", gap: "4rem", margin: "3rem 0rem" }}>
          <CardMedia
            component="img"
            alt="promo"
            // height="200vmin"
            // width="480"
            image={promoDetails && promoDetails.image}
          />
          <CardContent style={{ position: "absolute", top: 0, padding: "7vmin", fontFamily: 'Gilroy-SemiBold', color: "#FFFFFF" }}>
            <Typography variant='h3'>
              {promoDetails && promoDetails.description}
            </Typography>
            <Typography variant="h6">
              {promoDetails && promoDetails.name}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ width: "35vmin", height: "60vmin", position: "relative" }}>
          <CardMedia
            component="img"
            image={homePage}
            height="85%"
            alt="home"
          />
          <CardContent style={{ position: "absolute", top: "7vmin" }}>
            <Card sx={{ position: "relative", display: "flex", background: "transparent", border: "none", boxShadow: "none", gap: "4rem" }}>
              <CardMedia
                component="img"
                alt="promo"
                // height="9"
                // width="100"
                image={promoDetails && promoDetails.image}
              />
              <CardContent style={{ position: "absolute", top: 0, padding: "2.5vmin", fontFamily: 'Gilroy-SemiBold', color: "#FFFFFF" }}>
                <Typography variant='h5' style={{ fontSize: "3vmin" }}>
                  {promoDetails && promoDetails.description}
                </Typography>
                <Typography variant="body2" style={{ fontSize: "1.3vmin" }}>
                  {promoDetails && promoDetails.name}
                </Typography>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default PromoCreate