import React from "react";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { Box, Button, useMediaQuery } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useNotify } from "react-admin";


const useStyles = makeStyles({
    maincontainer: { display: "flex", justifyContent: 'center', alignItems: "center", width: "100vw", height: "101vh", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "white", zIndex: 1000 },
    childContanerWeb: { display: "flex", justifyContent: 'center', alignItems: "center", width: "100%", height: "100%", background: "#1D3688", padding: "2rem" },
    childContanerDesktop: { display: "flex", justifyContent: 'center', alignItems: "center", width: "40%", height: "fit-content", background: "#1D3688", borderRadius: "10px" },
    centerContainer: { display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center' }
})

export const SuccessQuotationSharePage = (props) => {

    const theme = useTheme();
    const classes = useStyles();
    const notify = useNotify();


    const isMobile = useMediaQuery(() =>
        theme.breakpoints.down("md")
    );

    const sendEmail = () => {
        const recipientEmail = props.quotationUserInfo.email;
        const subject = `${props.quotationUserInfo.vehicle_model ? props.quotationUserInfo.vehicle_model + " : " : ""}Car quotation`;
        const body = props.quotationUserInfo.share_url;

        const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        window.open(mailtoLink);
    }

    const sendSMS = () => {
        const recipientNumber = props.quotationUserInfo.phone
        const message =  props.quotationUserInfo.share_url;

        let checkiOS = /iPad|iPhone|MacIntel|iPod/.test(window.navigator.platform);
      

        let smsLink;
        if(checkiOS){
            smsLink = `sms:+91${recipientNumber}&body=${encodeURIComponent(message)}`;
        } else {
            smsLink = `sms:+91${recipientNumber}?body=${encodeURIComponent(message)}`;
        }

        window.open(smsLink);
      };
    
    const handleShareWhatsApp = () => {
        const recipientNumber = props.quotationUserInfo.phone
        const message =  props.quotationUserInfo.share_url;

        window.open(`https://wa.me/+91${recipientNumber}?text=${encodeURIComponent(message)}`, '_blank');
    }

    const handleCopyShareUrl = (url) => {
        const element = document.createElement("textarea");
        element.value = url;
        document.body.appendChild(element);
        element.select();
        document.execCommand("copy");
        document.body.removeChild(element);
        notify("📋 Copied to clipboard")
      };

    return (
        <Box id="something" className={classes.maincontainer} >
            <Box className={isMobile ? classes.childContanerWeb : classes.childContanerDesktop} >
                <Box className={classes.centerContainer} >
                    <Box sx={{ marginY: "5rem" }}>
                        <h1>Success!</h1>
                        <br />
                        <h5 style={{ fontSize: "1.2rem" }} >You have successfully created quotation</h5>
                    </Box>

                    <Box className={classes.centerContainer} >
                        <Box className={classes.centerContainer} sx={{ flexDirection: 'row' }} >
                            <ShareIcon sx={{ marginX: "0.5rem" }} /> <span style={{ fontSize: "1rem" }} >Share Link :</span>
                        </Box>
                        <Box className={classes.centerContainer} sx={{ flexDirection: 'row', paddingTop: "0.8rem" }} >
                            <WhatsAppIcon onClick={handleShareWhatsApp} sx={{ fontSize: "2rem", margin: "0.9rem", cursor: 'pointer' }}/>
                            <SmsIcon onClick={sendSMS} sx={{ fontSize: "2rem", margin: "0.9rem", cursor: 'pointer' }} />
                            <EmailIcon onClick={sendEmail} sx={{ fontSize: "2rem", margin: "0.9rem", cursor: "pointer" }} />
                            <ContentCopyIcon onClick={(e) => { handleCopyShareUrl(props.quotationUserInfo.share_url) }} sx={{ fontSize: "2rem", margin: "0.9rem", cursor: "pointer" }} />
                        </Box>
                    </Box>

                    <Box sx={{ width: "100%" }} >
                        <Button variant="contained" onClick={() => props.goBack()} sx={{ width: "20rem", height: "2.3rem", background: "#2d2d2d", marginY: "5rem" }}>Done</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}