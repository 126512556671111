import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, Button, CircularProgress, LinearProgress, List, ListItem, ListItemButton, ListItemText, Popover, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import ContainerWithTitle from "../../UIComponents/Containers/ContainerWithTitle";
import { Confirm, Datagrid, ListContextProvider, TextField, useGetList, useList, useNotify } from "react-admin";
import CallIcon from '@mui/icons-material/Call';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplyIcon from '@mui/icons-material/Reply';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import moment from "moment";
import { makeAPICall } from "../../action/APIClient/APIClient";
import { useDispatch } from "react-redux";
import { setShareScreenConfig } from "../../store/reducer1";


const styles = {
    tableRowCell: { fontFamily: 'Avenir Next', fontWeight: "400", fontSize: "18px", textAlign: "center", lineHeight: "27px", letterSpacing: "0.005em", color: "#262A38", whiteSpace: "nowrap" },
    datarid: { overflowY: "scroll", borderRadius: "24px", width: "100%", maxHeight: "400px", "& .RaDatagrid-thead": { height: "56px" }, whiteSpace: "nowrap", color: "#656665", fontWeight: "1000", backgronuColor: "black" },
    dataGridHeadingText: { fontWeight: 550 },
}

const useStyles = makeStyles({
    mobileMainContainer: { width: "10rem" },
    createQuotationContainer: { position: "fixed", width: "100%", bottom: "0", paddingBottom: "1rem", left: "50%", transform: "translateX(-50%)", background: "white", height: "5rem", display: "flex", justifyContent: 'center', alignItems: 'center' },
    createQuotationButtonDesktop: { textAlign: "left", width: "98%", paddingTop: "2rem", paddingBottom: "2rem" },
    flexRowContainer: { display: "flex", alignItems: "center", justifyContent: "space-between" },
    textOverflow: { maxWidth: '160px', overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: "nowrap" },
    visuallyHiddenDiv: { position: "absolute", zIndex: '1000', opacity: 0 },
    popoverDesign: {boxShadow:"none !important", padding:"5rem"}
})


export const ListQuotation = (props) => {

    const theme = useTheme();
    const classes = useStyles();
    const notify = useNotify();
    const dispatch = useDispatch()

    const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = React.useState(false);
    const [deleteQuotationRecord, setDeleteQuotationRecord] = React.useState(null);
    const [deleteQuotationInProgress, setDeleteQuotationInProgress] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuId, setMenuId] = React.useState(null);
    const [menuItemList, setMenuItemList] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);

    const handleCloseMenu = () => {
        setOpenMenu(false); 
        setMenuItemList(null);
        setAnchorEl(null)
    }

    const isMobile = useMediaQuery(() =>
        theme.breakpoints.down("md")
    );

    const { data, isLoading, error, isError, refetch } = useGetList(
        `quotations/`
    );

    const listContext = useList({ data });

    const getFormatedDate = (date) => {

        let localDate = new Date(date)
        let formatedDate = moment(localDate).format("DD MMM, YYYY");

        return formatedDate.toString();
    }

    const handleCallClick = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleShareMessage = (quotationInfo) => {
        dispatch(setShareScreenConfig({
            renderShareScreen: true,
            shareScreenRequiredInfo: {
                message: quotationInfo.share_url,
                phoneNumber: quotationInfo.phone,
                email: quotationInfo.email,
                mailSub: `${quotationInfo.vehicle_model ? quotationInfo.vehicle_model + " : " : ""}Car quotation`,
            }
        }))
    }

    const handleCopyShareUrl = (url) => {
        const element = document.createElement("textarea");
        element.value = url;
        document.body.appendChild(element);
        element.select();
        document.execCommand("copy");
        document.body.removeChild(element);
        notify("📋 Copied to clipboard")
    };

    const handleShowDeletePopup = () => {
        setDeleteQuotationRecord(menuItemList);
        setShowDeleteConfirmPopup(true)
    }

    const closeQuotationDeletePopup = () => {
        setShowDeleteConfirmPopup(false);

        setTimeout(() => {
            setDeleteQuotationRecord(null);
        }, 100);
    }

    const deleteQuoataion = () => {
        setDeleteQuotationInProgress(true);
        makeAPICall("DELETE", `auto-care/quotations/${deleteQuotationRecord.id}/`, null, true)
            .then((res) => { notify("Quotation deleted successfully"); refetch(); setDeleteQuotationInProgress(false); closeQuotationDeletePopup() })
            .catch((err) => { notify("Something went wrong"); setDeleteQuotationInProgress(false); closeQuotationDeletePopup() })
    }

    const stringToColor = (string) => {
        let hash = 0;
        let i;
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
    }

    const stringAvatar = (name) => {

        console.log(name)
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    React.useEffect(() => {
        setOpenMenu(Boolean(anchorEl));
        setMenuId(Boolean(anchorEl) ? 'simple-popover' : undefined);
    }, [anchorEl]);

    return !isLoading && !isError && data[0].results ? (
        <ContainerWithTitle title="Quotation" extraStyles={{ padding: "0% 4% 0% 0%" }} border="none" height="auto" width="100%" component={
            <>
                {
                    isMobile ?
                        <>
                            <Box sx={{ padding: "0.5rem", paddingTop: "1rem", width: "100%", maxWidth: "95vw", overflowY: "scroll", height: "70vh" }} >
                                {
                                    data[0].results.length == 0 ?

                                        <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection: "column", paddingY: "5rem", width: "100%", border: "1px solid #D0D5DE", borderRadius: "12px", minHeight: "35rem" }}>
                                            <Typography variant='h4' fontWeight={700} color="#8a787a" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Create your first quotation...</Typography>
                                        </Box> :

                                        data[0].results.map((quotationInfo) => {
                                            return (
                                                <Box sx={{ paddingY: "0.8rem", height: "10rem" }}>
                                                    <Box className={classes.flexRowContainer}>

                                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", minHeight: "7rem", width: "15%" }} >
                                                            <Stack sx={{ display: "flex", alignItems: "start", justifyContent: "center" }} direction="row">
                                                                <Avatar style={{ width: "46px", height: "46px" }} alt="img" {...stringAvatar(quotationInfo.first_name + " " + quotationInfo.last_name)} />
                                                            </Stack>
                                                        </Box>

                                                        <Box sx={{ textAlign: "left", width: "50%", height: "7rem", paddingLeft: "0.4rem" }}>
                                                            <Box className={classes.textOverflow} ><h5 style={{ fontSize: "1.18rem" }}>{quotationInfo.first_name + " " + quotationInfo.last_name}</h5></Box>
                                                            <Box className={classes.textOverflow} >Vehicle: {quotationInfo.vehicle_model}</Box>

                                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", marginTop: "0.5rem", width: "100%" }}>
                                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                                    <LinearProgress variant="determinate" color={quotationInfo.interested.code == 1 ? "success" : quotationInfo.interested.code == 0 ? "error" : "inherit"} sx={{ width: "75%", color: "grey" }} value={10} />
                                                                    <p style={{ paddingX: "10px", color: quotationInfo.interested.code == 1 ? "#2e7d32" : quotationInfo.interested.code == 0 ? "#d32f2f" : "grey" }} >10%</p>
                                                                </Box>
                                                                <Box sx={{ width: "100%", fontWeight: "500" }} >{quotationInfo.interested.label}</Box>
                                                            </Box>
                                                        </Box>

                                                        <Box sx={{ width: "35%", height: "7rem", paddingX: "0.2rem" }}>
                                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
                                                                <Box className={classes.textOverflow} sx={{ textAlign: "left", fontSize: "0.8rem" }} >{getFormatedDate(quotationInfo.created_at)}</Box>
                                                                <Box>
                                                                    <MoreVertIcon aria-describedby={menuId} onClick={(e) => { setMenuItemList(quotationInfo); setAnchorEl(e.currentTarget) }} />
                                                                    <Popover
                                                                        id={menuId}
                                                                        open={openMenu}
                                                                        anchorEl={anchorEl}
                                                                        onClose={() => { handleCloseMenu() }}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                    >
                                                                        <Box>
                                                                            <Button onClick={() => {handleShowDeletePopup();handleCloseMenu()}} variant="outlined" color="inherit" startIcon={<DeleteOutlineIcon />}>Delete</Button>
                                                                        </Box>
                                                                    </Popover>
                                                                </Box>
                                                            </Box>

                                                            <Box sx={{ display: "flex", width: "100%", alignItems: 'center', justifyContent: "flex-end", marginTop: "0.8rem", paddingRight: "4px" }}>
                                                                <Box sx={{ paddingX: "4px" }} onClick={() => handleShareMessage(quotationInfo)} ><ReplyIcon /></Box>
                                                                <Box sx={{ paddingX: "4px" }} onClick={() => handleCallClick(quotationInfo.phone)} ><CallIcon /></Box>
                                                                {/* <Box sx={{ paddingX: "6px" }} onClick={() => { handleShareWhatsApp(quotationInfo.phone, quotationInfo.share_url)}} > <WhatsAppIcon fontSize="small" /></Box> */}
                                                                {/* <Box sx={{ paddingX: "6px" }} onClick={() => { handleCopyShareUrl(quotationInfo.share_url) }} ><ContentCopyIcon fontSize="small" /></Box> */}
                                                                {/* <Box sx={{ paddingX: "2px" }}> <ArrowForwardIosIcon fontSize="small" /></Box> */}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <hr style={{ marginTop: "1.3rem", width: "100%" }} />
                                                </Box>
                                            )
                                        })
                                }
                                <Confirm
                                    isOpen={showDeleteConfirmPopup}
                                    title={`Delete Quotation for ${deleteQuotationRecord && deleteQuotationRecord.first_name + " " + deleteQuotationRecord.last_name}`}
                                    content="Are you sure you want to delete this quotation?"
                                    loading={deleteQuotationInProgress}
                                    onConfirm={deleteQuoataion}
                                    onClose={closeQuotationDeletePopup}
                                />
                                <Box className={classes.createQuotationContainer} >
                                    <Button size="large" variant="contained" sx={{ width: "80%", height: "3rem" }} onClick={() => props.openCreateQuotation()} >CREATE Quotation</Button>
                                </Box>
                            </Box>
                        </> : <>
                            <Box className={classes.createQuotationButtonDesktop} >
                                <Box style={{ display: "flex", alignItems: 'center', width: "fit-content", padding: "1rem", backgroundColor: "whitesmoke", borderRadius: "5px", color: "#194080", cursor: "pointer" }} onClick={() => props.openCreateQuotation()} ><h3 style={{ paddingRight: "2rem", fontSize: "1.2rem" }} >Create New Quotation </h3><AddCircleIcon fontSize="large" /></Box>
                            </Box>
                            <ContainerWithTitle title="Quotation Sent" extraStyles={{ padding: "0% 4% 0% 0%", width: "98%" }} height="100%" width="100%" component={
                                <>
                                    {
                                        data[0].results.length == 0 ?

                                            <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection: "column", paddingY: "5rem", minHeight: "23rem" }}>
                                                <Typography variant='h4' fontWeight={700} color="#8a787a" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Create your first quotation...</Typography>
                                            </Box> :

                                            <ListContextProvider value={{ ...listContext, data: listContext.data && listContext.data[0].results }}>
                                                <Datagrid bulkActionButtons={false} rowClick={false} stickyHeader={true} sx={{ ...styles.datarid, "& .RaDatagrid-headerCell": styles.tableRowCell, "& .MuiButtonBase-root": styles.dataGridHeadingText }} rowStyle={() => ({ height: "50px" })}>
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="first_name" label="First Name" />
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="last_name" label="Last Name" />
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="vehicle_model" label="Vehicle Model" />
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="interested.label" label="Interest Status" />
                                                    <TextField textAlign="center" style={styles.tableRowCell} source="views" label="Views" />
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} source="share_url" label="Quotation Link" ><TextField className={classes.visuallyHiddenDiv} textAlign="center" onClick={(e) => { navigator.clipboard.writeText(e.target.textContent).then(() => notify("Copied to clipboard")) }} style={styles.tableRowCell} source="share_url" />Copy Link <ContentCopyIcon sx={{ paddingLeft: "0.4rem" }} /></Box>
                                                </Datagrid>
                                            </ListContextProvider>}
                                </>
                            } />
                        </>
                }
            </>
        } />
    ) : (isError || error) ? (
        <ContainerWithTitle title="Quotation" extraStyles={{ padding: "0% 1% 0% 0%" }} height="80vh" width="100%" component={
            <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection: "column", paddingY: "5rem" }}>
                <Typography variant='h4' fontWeight={800} color="#8a787a" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Something Went Wrong :(</Typography>
                {error.detail && <Typography variant='h5' fontWeight={800} color="#828282" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Error Details : {error.detail}</Typography>}
            </Box>
        } />
    ) : !isError && (
        <ContainerWithTitle title="Quotation" extraStyles={{ padding: "0% 1% 0% 0%" }} height="80vh" width="100%" component={
            <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection: "column", paddingY: "5rem" }}>
                <CircularProgress sx={{ color: "#828282" }} />
                <Typography variant='h5' fontWeight={800} color="#828282" align='center' marginTop={"1rem"} paddingLeft="0.8rem" >Loading...</Typography>
            </Box>
        } />
    )
}