import React, { useState } from 'react'
import { Box, Checkbox, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ColorPickerInput } from '../../../UIComponents/colorPicker/colorPickerInput';
import { Button } from 'react-admin';


const useStyles = makeStyles({
    mainContainer: { display: "flex", alignItems: "center", width: "100%", height: "100%", padding: '1rem' },
    heading: { fontSize: "1.8rem", fontWeight: "500", textAlign: "left", width: '100%' },
    label: { textAlign: 'left' },
    input: { width: "100%", borderRadius: '22px' },
    select: { width: "100%", height: "3rem", borderRadius: '22px' },
    inputContainerSingle: { padding: "1rem" },
    inputContainerDouble: { display: 'flex', alignItems: "center", justifyContent: "space-between", padding: '1rem', },
    inputChildContainerDouble: { width: "48%" },
    buttonPrimary: {width:"8rem", height:"2.2rem"}
})

function VehicleListingForm(props) {

    const classes = useStyles();
    const [page, setPage] = useState(1);

    const [fuel, setFuel] = React.useState(["Petrol", "Diesel", "CNG", "Electric"]);

    const handleMultipleSelect = (initValue, selectedValue, fieldName) => {
        const indexSelectedValue = initValue.indexOf(selectedValue);

        if (selectedValue) {
            if (indexSelectedValue !== -1) {
                initValue.splice(indexSelectedValue, 1);
            } else {
                initValue.push(selectedValue);
            }
        }

        props.handleVehicleInfo(fieldName, initValue);
    }

    const handleArrayInput = (input, fieldName) => {
        let formatedInput;

        if (input) {
            formatedInput = input.split(",").map(colorCode => colorCode.trim());
        } else {
            formatedInput = [];
        }

        props.handleVehicleInfo(fieldName, formatedInput);
    }

    return (
        <Box className={classes.mainContainer}>

            {page == 1 ?
                <Box style={{ width: '100%' }} >
                    <Box sx={{ width: "100%", paddingY: "1rem" }} >
                        <p className={classes.heading} >Vehicle Listing</p>
                    </Box>

                    <Box className={classes.inputContainerSingle} >
                        <InputLabel className={classes.label} >Vehicle Name</InputLabel>
                        <TextField className={classes.input} variant="outlined" value={props.vehicleListInfo.name} onChange={(e) => props.handleVehicleInfo("name", e.target.value)} placeholder='Enter vehicle name' />
                    </Box>

                    <Box className={classes.inputContainerDouble}>
                        <Box className={classes.inputChildContainerDouble} >
                            <InputLabel className={classes.label} >Modal</InputLabel>
                            <TextField className={classes.input} variant="outlined" value={props.vehicleListInfo.modal} onChange={(e) => props.handleVehicleInfo("modal", e.target.value)} placeholder='Enter vehicle modal' />
                        </Box>
                        <Box className={classes.inputChildContainerDouble}>
                            <InputLabel className={classes.label} >Fuel</InputLabel>
                            <Select
                                size="small"
                                className={classes.select}
                                value={props.vehicleListInfo.fuel}
                                onChange={(e) => handleMultipleSelect([...props.vehicleListInfo.fuel], e.target.value, "fuel")}
                                variant="outlined"
                                renderValue={(selected) => { return selected.join(', ') }}
                                sx={{ height: "2.4rem", marginY: "7px" }}
                            >
                                <MenuItem value="" disabled >Select Fuel Type</MenuItem>
                                {fuel && fuel.map(fuelType => {
                                    return (
                                        <MenuItem value={fuelType}>
                                            <Checkbox size="small" checked={props.vehicleListInfo.fuel.indexOf(fuelType) > -1} />
                                            <ListItemText primary={fuelType} />
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Box>

                    <Box className={classes.inputContainerDouble}>
                        <Box className={classes.inputChildContainerDouble} >
                            <InputLabel className={classes.label} >Dealership Id</InputLabel>
                            <TextField className={classes.input} variant="outlined" value={props.vehicleListInfo.dealershipId} onChange={(e) => props.handleVehicleInfo("dealershipId", e.target.value)} placeholder='Enter vehicle dealership id' />
                        </Box>
                        <Box className={classes.inputChildContainerDouble} >
                            <InputLabel className={classes.label} >Manufacture Year</InputLabel>
                            <TextField className={classes.input} type="number" inputProps={{ maxLength: 4, min: 1900, max: new Date().getFullYear() }} variant="outlined" value={props.vehicleListInfo.manufactureYear} onChange={(e) => props.handleVehicleInfo("manufactureYear", e.target.value)} placeholder='Enter vehicle manufacture year' />
                        </Box>
                    </Box>

                    <Box className={classes.inputContainerSingle}>
                        <ColorPickerInput colorCodes={props.vehicleListInfo.colorCode} next={(colorArray) => props.handleVehicleInfo("colorCode", colorArray)} />
                    </Box>

                    <Box className={classes.inputContainerDouble}>
                        <Box className={classes.inputChildContainerDouble} >
                            <InputLabel className={classes.label} >Gear Box</InputLabel>
                            <TextField className={classes.input} variant="outlined" value={props.vehicleListInfo.gearBox} onChange={(e) => props.handleVehicleInfo("gearBox", e.target.value)} placeholder='Enter gear box' />
                        </Box>
                        <Box className={classes.inputChildContainerDouble} >
                            <InputLabel className={classes.label} >Mileage</InputLabel>
                            <TextField className={classes.input} type="text" variant="outlined" value={props.vehicleListInfo.mileage} onChange={(e) => props.handleVehicleInfo("mileage", e.target.value)} placeholder='Enter mileage' />
                        </Box>
                    </Box>

                    <Box className={classes.inputContainerSingle} >
                        <InputLabel className={classes.label} >Engine Type</InputLabel>
                        <TextField rows={2} multiline className={classes.input} variant="outlined" value={props.vehicleListInfo.engine.join(',\n')} onChange={(e) => { handleArrayInput(e.target.value, "engine") }} placeholder='Enter available engine options, seperated by comma.' />
                    </Box>

                    <Box className={classes.inputContainerDouble}>
                        <Box className={classes.inputChildContainerDouble} >
                            <InputLabel className={classes.label} >Price</InputLabel>
                            <TextField className={classes.input} type='number' variant="outlined" value={props.vehicleListInfo.price}
                                InputProps={{ startAdornment: <InputAdornment position="start">₹</InputAdornment> }}
                                onChange={(e) => props.handleVehicleInfo("price", e.target.value)}
                                placeholder='Enter Price'
                            />
                        </Box>
                        <Box className={classes.inputChildContainerDouble} >
                            <InputLabel className={classes.label} >Stock</InputLabel>
                            <TextField className={classes.input} type="number" variant="outlined" value={props.vehicleListInfo.stock} onChange={(e) => props.handleVehicleInfo("stock", e.target.value)} placeholder='Enter available stock' />
                        </Box>
                    </Box>


                    <Box className={classes.inputContainerDouble} sx={{paddingY:'4rem', paddingX:"1.5rem"}}>
                            <Button className={classes.buttonPrimary} color="inherit" variant="outlined">PREV</Button>
                            <Button className={classes.buttonPrimary} color="primary" variant="outlined">NEXT</Button>
                    </Box>
                </Box>
                : null}

        </Box>
    )
}

export default VehicleListingForm