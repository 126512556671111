import * as React from 'react';
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';

const data = [
  { month: 'Jan', services: 90 },
  { month: 'Fab', services: 40 },
  { month: 'Mar', services: 60 },
  { month: 'Apr', services: 50 },
  { month: 'May', services: 80 },
  { month: 'Jun', services: 70 },
  { month: 'Jul', services: 57 },
  { month: 'Aug', services: 90 },
  { month: 'Sep', services: 40 },
  { month: 'Oct', services: 50 },
  { month: 'Nov', services: 70 },
  { month: 'Dec', services: 50 },
];

export default class BarChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data,
    };
  }

  render() {
    const { data: chartData } = this.state;

    return (
        <Chart
          data={chartData}
          width={500}
          height={300}
          
        >
          {/* <ArgumentAxis />
          <ValueAxis /> 
          
          TODO: App is breaking after adding these axes
          
          */}

          <BarSeries
            valueField="services"
            argumentField="month"
          />
          <Animation />
        </Chart>
    );
  }
}
