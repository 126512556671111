import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
    container: {display: "flex"},
    value: {fontFamily: 'Avenir Next !important', fontWeight: "500  !important", fontSize: "45px !important", lineHeight: "45px !important", letterSpacing: "0.005em !important"},
    description: {fontFamily: 'Avenir Next !important', fontWeight: "500  !important", fontSize: "14px !important", lineHeight: "23px !important", letterSpacing: "0.005em !important"},
    title: {fontFamily: 'Avenir Next !important', fontWeight: "400  !important", fontSize: "18px !important", lineHeight: "27px !important", letterSpacing: "0.005em !important", color: "#444C66 !important"},
    divider: {width: "0px", height: "70px", border: "3px solid #F0F3FB", marginLeft: "6rem", marginTop: "1rem"}
})

function StatusContainer(props) {
    const classes = useStyles()
  return (
    <div className={classes.container}>
        <div>
            <Typography className={classes.value} style={{color: props.textColor}}>{props.value}  {props.icon}</Typography>
            <Typography className={classes.description} style={{color: props.textColor}} variant="subtitle1">{props.description}</Typography>
            <Typography className={classes.title}>{props.title}</Typography>
        </div>
        {props.showDivider && <span className={classes.divider}></span>}
    </div>
  )
}

export default StatusContainer